.notification-container {
  height: 40vh;
  overflow: scroll;
  padding: 10px 0;

  .single-notification-item {
    margin: 5px 10px;
    margin-bottom: 20px;
  }

  .notification-icon {
    color: $primary-color !important;
  }

  .notification-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(92, 97, 242, 0.1);
    height: 36px;
    width: 36px;
    border-radius: 100%;
  }
  .notification-time {
    margin: auto 0;
    color: $primary-color !important;

  }
}