/**=============================
	68. theme layout CSS Start
================================**/
$dark-background: #383838;
$dark-card-inbox: #323846;
$dark-small-font-color: #98a6ad;
$dark-all-font-color: rgba(255, 255, 255, 0.8);
$light-all-font-color: rgba(255, 255, 255, 0.2);
$sidebar-submenu-font-color: rgba(255, 255, 255, 0.6);

body {
  &.dark-only {
    color: $dark-all-font-color;
    background-color: $dark-body-background;
    .employee-status .d-flex .flex-grow-1 h5 a {
      color: $dark-all-font-color;
    }
    .footer {
      background-color: $dark-body-background;      
    }
    .quiz-title {
      background-color: $dark-body-background;
    }
    .checkedColor:hover+label {
      background-color: rgba(16, 16, 28, 0.5);
    }
    
    .question-progress {
      background-color: $dark-card-background;
    }
    .reactour__popover {
      color: $dark-all-font-color !important;
      background-color: $dark-card-background !important;

      >span {
        background-color: $primary-color !important;
      }
      
      >button {
        color: $dark-all-font-color !important;
      }
    }
    .quiz-count-down {
      background-color: $dark-card-background;      
    }
    .file-upload-lebel {
      background-color: $dark-card-background;
    }
    .option-label {
      background-color: $dark-body-background;
      color: $dark-all-font-color;
    }
    .checkedColor:checked+label {
        background-color: $primary-color;
        color: white;
              
      :hover {
        background-color: $light-all-font-color;
      }
    }

    .id-card-id {
      color: $theme-font-color;
    }
    .id-card-name {
      color: $theme-font-color;
    }
    .id-card-contact {
      color: $theme-font-color;
    }
    .table {
      th {
        background-color: $dark-card-background;
      }
      td {
        background-color: $dark-card-background;
      }
      tr {
        border-color: $dark-card-border;
      }
    }
   
      .fc-toolbar-title {
        color: $dark-all-font-color;
      }
      .show-color {
        color: $dark-all-font-color !important;
        span {
          color: $dark-all-font-color;
        }
      }
      .quiz-option {
        border-color: $dark-card-border;
      }
      .quiz-border {
        border-color: $dark-card-border;
      }
      .bottom-border {
        border-color: $dark-card-border;
      }

    .user-status {
      table {
        tr {
          th,
          td {
            &:first-child {
              a {
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
    }
    .todo-wrap{
      .card-header{
        &.b-bottom{
          border-bottom: 1px solid $dark-card-border;
        }
      }
    }
    .right-header{
      .input-group{
        .Typeahead-menu{
          .ProfileCard{
            &:hover{
              background-color: $dark-card-background;
            }
          }
        }
      }
    }
    .form-control {
      background-color: $dark-body-background;
      color: $dark-small-font-color;
      border: 1px solid $dark-card-border;
    }
    .dzu-previewButton{
      filter: brightness(0) invert(.7);
    }
    .custom-profile .profile-details h4 a {
      color: $dark-all-font-color;
    }
    .apexcharts-tooltip.light {
      .apexcharts-tooltip-title {
        background: $dark-card-background;
        color: $white;
      }
    }
    .basic-dropdown{
      ul{
        li{
          span.f-w-600{
            color: $dark-all-font-color;
            &::before{
              background-color: rgba($white, 0.1);
            }
          }
        }
      }
      .left-dropdown{
        background-color: $dark-body-background;
        a{
          color: $dark-all-font-color;
        }
      }
    }
    hr {
      background-color: $dark-card-background;
      border: 1px solid $dark-card-border;
    }
    .progress {
      background-color: $dark-card-border;
    }
    .card {
      background-color: $dark-card-background;
    }
    .ecommerce-widget {
      border: 1px solid $dark-card-border !important;
    }
    .btn-light {
      color: $dark-all-font-color !important;
    }
    .b-r-light {
      border-color: $dark-card-border !important;
    }
    .form-select {
      color: rgba(255, 255, 255, 0.5);
    }
    .add-project {
      .text-inherit {
        color: $dark-all-font-color;
      }
    }
    .login-card{
      .login-main1.login-tab1{
        .nav-tabs{
          .nav-item{
            .nav-link{
              background-color: $dark-body-background;
            }
          }
        }
      }
    }    
    // tree view
    .deni-react-treeview-item-container{
      .checkbox.selected{
        background-color: $dark-body-background;
      }
    }
    .deni-react-treeview-container.classic{
      .deni-react-treeview-item-container.classic{
        &.selected{
          outline-color: $dark-card-border !important;
        }
      }
    }
    .deni-react-treeview-container.classic{
      border-color: $dark-card-border !important;
      .deni-react-treeview-item-container.classic{
        color: $dark-all-font-color !important;
        &:hover.select-row{
          background-color: $dark-body-background;
          border-color: $dark-card-border;
        }
        .checkbox{
          border-color: $dark-card-border !important;
        }
      }
    } 
    .deni-react-treeview-container{
      background-color: $dark-card-background !important;
    }
    .ui-list{
      .list-group-item{
        border: 1px solid $dark-card-border;
      }
      .tab-javascript{
        ul.list-group{
          + ul.list-group{
            li{
              border-top: 0;
            }
          }
        }
      }
      ul.list-group-flush.list-group{
        .list-group-item{
          border: 0;
          + .list-group-item{
            border-top: 1px solid $dark-card-border;
          }
        }
      }
      ul.list-group{
        .list-group-item.d-flex{
          color: $dark-all-font-color;
        }
        .list-group-item{
          background-color: $dark-card-background;
          border: 1px solid $dark-card-border;
          + .list-group-item{
            border-top: 0;
          }
          &.list-group-item-primary {
            color: #004085;
            background-color: #b8daff;
          }
          &.list-group-item-secondary {
            color: #383d41;
            background-color: #d6d8db;
          }
          &.list-group-item-success {
            color: #155724;
            background-color: #c3e6cb;
          }
          &.list-group-item-danger {
            color: #721c24;
            background-color: #f5c6cb;
          }
          &.list-group-item-warning {
            color: #856404;
            background-color: #ffeeba;
          }
          &.list-group-item-info {
            color: #0c5460;
            background-color: #bee5eb;
          }
          &.list-group-item-light {
            color: #818182;
            background-color: #fdfdfe;
          }
          &.list-group-item-dark {
            color: #1b1e21;
            background-color: #c6c8ca;
          }
        }
      }
    }
    blockquote {
      border-left: 4px solid $dark-card-border;
      &.text-end{
        border-right: 4px solid $dark-card-border;
        border-left: unset;
      }
      &.text-center{
        border: 0;
      }
    }
    .page-wrapper {
      .page-body-wrapper {
        .page-body {
          .card:not(.email-body) {
            .bottom-content {
              .b-r-light {
                border-left: unset !important;
              }
            }
          }
        }
      }
    }
    .knowledgebase-search {
      color: $theme-body-font-color;
      .form-control-plaintext {
        color: $theme-body-font-color;
        background-color: transparent !important;
        border: transparent !important;
      }
    }
    .btn-close {
      filter: brightness(0.8) invert(1);
    }
    .prooduct-details-box {
      .d-flex {
        border: 1px solid $dark-card-border;
      }
    }
    .pricingtable {
      box-shadow: 0 0 40px rgba($dark-card-border, 0.5);
    }
    .product-price {
      del {
        color: $dark-small-font-color;
      }
    }
    .form-control-plaintext {
      color: $dark-all-font-color;
    }
    .form-select {
      background-color: $dark-body-background;
      border: 1px solid $dark-card-border;
    }
    .fc-theme-standard{
      .fc-scrollgrid{
        border: 1px solid $dark-card-border;
      }
    } 
    .btn-group{
      .checkbox-light{
        label{
          &::before{
            border-color: $dark-card-border !important;
          }
        }
      }
    }
    .chat-box{
      .chat-right-aside{
        .chat-history{
          ul{
            li{
              &:hover{
                z-index: 0;
              }
            }
          }
        }
      }
      .people-list{
        ul.list{
          li{
            background-color: $transparent-color !important;
          }
        }
      }
    }
    .social-status {
      .d-flex {
        .flex-grow-1 {
          a {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .order-history{
      table{
        .qty-box{
          border: 1px solid $dark-card-border;
          input{
            &.input-number.form-control{
              background-color: $dark-card-background;
              border: 0;
            }
          }
        }
      }
    }
    .user-card{
      .custom-card{
        .card-social{
          border: 0;
        }
      } 
    }
    .left-header {
      .left-menu-header {
        ul.header-left {
          li {
            span.f-w-600 {
              &::before {
                z-index: 0;
                background-color: rgba($white, 0.1);
              }
            }
          }
        }
      }
    }
    .box-layout {
      background-color: $dark-body-background;
      .page-wrapper,
      &.page-wrapper {
        .page-header {
          .header-wrapper {
            border: 1px solid $dark-border-color;
          }
        }
      }
    }
    .form-check-input {
      background-color: $dark-body-background;
    }
    .switch-state {
      &:before {
        background-color: $dark-background;
      }
    }
    .icon-state {
      .switch-state {
        &:after {
          color: $dark-all-font-color;
        }
      }
    }
    .chat-box {
      .chat-menu {
        .user-profile {
          .user-content {
            h5 {
              a {
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
      .about {
        .name {
          a {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .file-manager {
      > h6 {
        color: $dark-all-font-color;
      }
    }
    .note-editor {
      i {
        color: $dark-all-font-color;
      }
      span {
        color: $dark-all-font-color;
      }
      i.note-recent-color {
        color: $theme-body-font-color;
      }
    }
    .form-switch {
      .form-check-input {
        background-color: $dark-background;
        &:checked {
          background-color: $primary-color;
        }
      }
    }
    ol{
      &.progtrckr{
        li{
          color: $dark-all-font-color;
          &.progtrckr-doing{
            border-bottom: 4px solid $dark-card-border;
            &::before{
              background-color: $dark-body-background;
              color: $dark-card-background;
            }
          }
          &.progtrckr-todo{
            &::before{
              color: $dark-card-border;
            }
          }
        }
      }
    }
    //select 2 
    .css-13cymwt-control{
      background-color: $dark-body-background !important;
      border: 1px solid $dark-card-border !important;
    }
    .page-wrapper {
      &.horizontal-wrapper {
        .page-body-wrapper {
          .sidebar-wrapper {
            .sidebar-main {
              .sidebar-links {
                .simplebar-wrapper {
                  .simplebar-mask {
                    .simplebar-content-wrapper {
                      .simplebar-content {
                        .menu-box {
                          > ul {
                            > li {
                              &.hoverd {
                                .sidebar-submenu {
                                  li {
                                    a {
                                      color: $dark-all-font-color;
                                      &.active {
                                        color: $primary-color;
                                      }
                                      &:after {
                                        background-color: $dark-all-font-color;
                                      }
                                    }
                                  }
                                }
                              }
                              > a {
                                color: $dark-all-font-color;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .page-header {
        .header-wrapper {
          .nav-right {
            .message-dropdown {
              li {
                .d-flex {
                  .flex-grow-1 {
                    h5 {
                      a {
                        color: $dark-all-font-color;
                      }
                    }
                  }
                }
              }
            }
            .bookmark-flip {
              .bookmark-dropdown {
                .bookmark-content {
                  .bookmark-icon {
                    border: 1px solid $dark-card-border;
                  }
                }
              }
            }
            .onhover-show-div:not(.profile-dropdown) {
              &.bookmark-flip {
                .bookmark-dropdown {
                  .ProfileCard-realName {
                    i {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
            }
            .onhover-show-div {
              &.bookmark-flip {
                .flip-card {
                  .flip-card-inner {
                    .back {
                      background-color: $dark-body-background;
                      .flip-back-content {
                        input {
                          background-color: $dark-card-background;
                          border: 1px solid $dark-card-border;
                          color: $dark-all-font-color;
                        }
                      }
                      ul {
                        li {
                          &:first-child {
                            background-color: transparent !important;
                          }
                        }
                      }
                    }
                    .front {
                      background-color: $dark-body-background;
                      a {
                        svg {
                          path {
                            stroke: $dark-all-font-color;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .cal-date-widget {
      .cal-datepicker {
        .datepicker-here {
          .react-datepicker {
            background-color: $dark-card-background;
            border: 0;
          }
          .react-datepicker__day-name,
          .react-datepicker__day,
          .react-datepicker__time-name {
            color: $dark-all-font-color;
          }
          .react-datepicker__day--outside-month {
            color: $dark-small-font-color;
          }
          .react-datepicker__header {
            background-color: $dark-card-background;
          }
        }
      }
    }
    .react-datepicker{
      background-color: $dark-body-background;
      border: 1px solid $dark-card-border;
      .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
        color: $dark-all-font-color;
      }
      .react-datepicker__day--outside-month{
        color: $dark-small-font-color;
      }
    }
    .react-datepicker__time-container{
      border-left: 1px solid $dark-card-border;
    }
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, 
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
      border-bottom-color: $dark-body-background;
    }
    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, 
    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after{
      border-top-color: $dark-body-background;
    }
    .react-datepicker__header{
      background-color: $dark-body-background;
    }
    .react-datepicker__day,
    .react-datepicker__month-text,
    .react-datepicker__quarter-text,
    .react-datepicker__year-text {
      &:hover {
        background-color: $dark-card-background;
      }
    }
    .react-datepicker__day--selected {
      background-color: $primary-color;
      &:hover {
        background-color: $primary-color;
      }
    }
    .react-datepicker__time-container {
      .react-datepicker__time {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
        .react-datepicker__time-box {
          ul.react-datepicker__time-list {
            li.react-datepicker__time-list-item {
              &:hover {
                background-color: $dark-card-background;
              }
            }
          }
        }
      }
    }
    // timeline css
    .vertical-timeline-element-content {
      background-color: $dark-body-background;
      border: 1px solid $dark-card-border;
      .vertical-timeline-element-content-arrow {
        border-right: 7px solid $dark-body-background;
      }
    }
    .vertical-timeline {
      &::before {
        background: $dark-body-background;
      }
    }
    .vertical-timeline-element-icon {
      box-shadow: 0 0 0 4px $dark-body-background,
        inset 0 2px 0 rgba($black, 0.08), 0 3px 0 4px rgba($black, 0.05);
    }
    .project-list {
      .border-tab {
        &.nav-tabs {
          .nav-item {
            .nav-link {
              border-bottom: $dark-card-border;
            }
          }
        }
      }
    }
    .ProfileCard-realName {
      .realname {
        color: $dark-all-font-color;
      }
    }
    .main {
      .langChoice {
        border-color: $dark-card-border;
        &:focus,
        &:focus-visible {
          outline-color: $dark-card-border;
        }
      }
    }
    .chart-block {
      .flot-chart-container {
        .legend {
          table {
            color: $dark-small-font-color !important;
            background-color: $dark-body-background;
          }
        }
      }
    }
    .add-post {
      .dropzone {
        .dz-preview {
          .dz-error-message {
            background-color: $dark-body-background !important;
          }
        }
      }
    }
    .comment-box {
      .comment-social {
        li {
          &:nth-child(n + 2) {
            border-left: 1px solid $dark-card-border;
          }
        }
      }
      .d-md-flex {
        h6 {
          color: $dark-all-font-color;
        }
        img {
          border: 1px solid $dark-card-border;
        }
      }
    }
    .chart-block {
      .chart-overflow {
        svg {
          line {
            stroke: $dark-body-background;
          }
          g {
            text {
              fill: $dark-all-font-color;
            }
          }
        }
      }
    }
    .apexcharts-yaxistooltip {
      color: $dark-all-font-color;
      background-color: $dark-body-background;
    }
    .apexcharts-xaxistooltip {
      color: $dark-all-font-color;
      background-color: $dark-body-background;
    }
    .apexcharts-yaxis-title {
      color: $dark-all-font-color;
    }
    .apexcharts-xaxistooltip-bottom {
      &:after {
        border-bottom-color: $primary-color;
      }
    }
    .onhover-show-div {
      background-color: $transparent-color;
    }
    .modal{
      &.product-box{
        background-color: transparent;
        .modal-header{
          background-color: $dark-card-background;
          .product-details{
            .product-qnty{
              input{
                background-color: $dark-body-background;
                color: $dark-all-font-color;
                border: 1px solid $dark-card-border;
              }
            }
            .product-size{
              ul{
                li{
                  button{
                    border: 1px solid $dark-card-border;
                  }
                }
              }
            }
          }
        }
      }
    }
    .product-box {
      background-color: $dark-card-background;
      .product-details {
        h4 {
          color: $dark-all-font-color;
        }
      }
      .modal {
        .modal-header {
          background-color: $dark-card-background;
        }
      }
    }
    .product-wrapper {
      .product-sidebar{
        .filter-section{
          .card{
            border-radius: 5px;
          }
        }
      }
      &.sidebaron {
        .product-sidebar {
          .filter-section {
            .card {
              .left-filter {
                border-color: $dark-card-border;
              }
            }
          }
        }
      }
    }
    .product-page-main {
      p {
        color: $dark-small-font-color;
      }
      .pro-group {
        &:nth-child(n + 2) {
          border-color: $dark-card-border;
        }
      }
      .border-tab {
        &.nav-tabs {
          .nav-item {
            .nav-link {
              &.active {
                color: $white !important;
              }
            }
          }
        }
      }
    }
    .color-selector {
      ul {
        li {
          border-color: $dark-card-border;
        }
      }
    }
    .product-table {
      .rdt_TableHead {
        .rdt_TableHeadRow {
          background-color: $dark-body-background;
          border-bottom: 1px solid $dark-card-border;
          .rdt_TableCol{
            > div{
              color: $dark-all-font-color;
            }
          }
        }
      }
      .rdt_TableBody {
        .rdt_TableRow {
          border-bottom: 1px solid $dark-card-border;
          .rdt_TableCell:first-child {
            background-color: $dark-body-background;
          }
          .rdt_TableCell:nth-child(n + 2) {
            background-color: $dark-card-background;
            color: $dark-all-font-color;
          }
        }
        .rdt_TableRow:nth-child(odd) {
          .rdt_TableCell:first-child {
            background-color: $dark-card-background;
          }
          .rdt_TableCell:nth-child(n + 2) {
            background-color: $dark-body-background;
          }
        }
      }
      .rdt_Pagination{
        background-color: $dark-body-background;
        color: $dark-small-font-color;
        > div{
          button{
            fill: $dark-small-font-color;
            svg{
              fill: $dark-small-font-color;
            }
          }
        }
      }
    }
    .bookmark-tabcontent {
      .list-bookmark.details-bookmark {
        .bookmark-card.card {
          .title-bookmark {
            border-top: unset;
          }
        }
      }
      .details-bookmark {
        .bookmark-card.card {
          .title-bookmark {
            border-top: 1px solid $dark-card-border;
          }
        }
      }
    }
    .product-social {
      li {
        a {
          border-color: $dark-card-border;
        }
      }
    }
    .invoice {
      .invo-header {
        border-color: $dark-card-border;
      }
    }
    .prooduct-details-box {
      .product-name {
        h6 {
          color: $dark-all-font-color;
        }
      }
    }
    .checkout {
      label {
        color: $dark-all-font-color;
      }
    }
    .page-wrapper {
      .page-header {
        .header-wrapper {
          .nav-right {
            .onhover-show-div:not(.profile-dropdown) {
              li {
                &:first-child {
                  background-color: rgba($primary-color, 0.1) !important;
                }
              }
            }
          }
        }
      }
    }
    .todo-wrap {
      .todo-list-body {
        .assign-name {
          color: $dark-small-font-color;
        }
      }
      .email-wrap {
        .email-app-sidebar {
          .main-menu {
            > li {
              a {
                &:hover {
                  background-color: transparent;
                  color: $primary-color;
                  .badge {
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
    .todo {
      .todo-list-wrapper {
        #todo-list {
          li {
            &:last-child {
              .task-container {
                border-bottom: 0;
              }
            }
            .task-container {
              background: $dark-card-background;
              border-bottom: 1px solid $dark-card-border;
              .task-label {
                color: $dark-all-font-color;
              }
              &:hover {
                h4 {
                  color: $white;
                }
              }
              .task-action-btn {
                .action-box {
                  &:hover:not(.active) {
                    background: $dark-card-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
            &.completed {
              .task-container {
                .task-label {
                  color: $primary-color;
                }
                .complete-btn {
                  &:hover {
                    border: 1px solid $success-color !important;
                  }
                }
              }
            }
          }
        }
        .todo-list-footer {
          .new-task-wrapper {
            textarea {
              background-color: $dark-card-background;
              border: 1px solid $dark-card-border;
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .border-bottom {
      border-bottom: 1px solid $dark-card-border !important;
    }
    .email-wrap {
      .email-right-aside {
        .email-body {
          .inbox {
            .d-flex {
              &:hover {
                background-color: rgba($primary-color, 0.1);
              }
            }
          }
        }
      }
      .email-wrapper {
        .emailread-group {
          &:nth-child(n + 2) {
            border-color: $dark-card-border;
          }
        }
      }
    }
    .cke_chrome {
      border-color: $dark-card-border;
    }
    .card {
      .icon-box {
        background-color: $dark-body-background;
        &:hover {
          background-color: rgba($primary-color, 0.1);
        }
      }
    }
    .apexcharts-tooltip.light {
      .apexcharts-tooltip-title {
        background: $dark-card-background;
        color: $white;
      }
    }
    .apexcharts-tooltip.apexcharts-theme-light {
      border: 1px solid $dark-card-border;
      background: $dark-body-background;
    }
    .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
      border: 1px solid $dark-card-border;
      background: $dark-body-background;
      color: $dark-all-font-color;
    }
    .apexcharts-tooltip-series-group {
      color: $dark-all-font-color !important;
    }
    .apexcharts-yaxistooltip,
    .apexcharts-xaxistooltip {
      color: $dark-all-font-color;
      background: $dark-body-background;
      border: 1px solid $dark-card-border;
    }
    .apexcharts-yaxistooltip-left {
      &::before,
      &::after {
        border-left-color: $dark-card-border;
      }
    }
    .apexcharts-xaxistooltip-bottom {
      &::before,
      &::after {
        border-bottom-color: $dark-card-border;
      }
    }
    .bottom-content {
      .apexcharts-canvas {
        span {
          color: $dark-all-font-color;
        }
      }
    }
    .apexcharts-canvas {
      .apexcharts-tooltip {
        border: 1px solid $dark-card-border;
        .apexcharts-tooltip-title {
          margin-bottom: 0;
        }
        .apexcharts-tooltip-series-group {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
        }
      }
      .apexcharts-yaxis {
        text {
          fill: $dark-small-font-color;
        }
      }
    }
    .apexcharts-xaxistooltip {
      background-color: $dark-body-background;
      color: $dark-all-font-color;
      border: 1px solid $dark-card-border;
    }
    .apexcharts-xaxistooltip-bottom:before {
      border-bottom-color: $dark-card-border;
    }
    .apexcharts-xaxistooltip-bottom:after {
      border-bottom-color: $dark-card-border;
    }
    .apexcharts-tooltip.apexcharts-theme-light {
      border: 1px solid $dark-card-border;
      background: $dark-body-background;
      .apexcharts-tooltip-text {
        color: $dark-small-font-color;
      }
      .apexcharts-tooltip-title {
        background: $dark-card-background;
        border-bottom: 1px solid $dark-card-border;
        color: $dark-small-font-color;
      }
    }
    .apexcharts-yaxis {
      text {
        color: $dark-all-font-color;
      }
    }
    .page-wrapper {
      .page-header {
        .header-wrapper {
          .nav-right {
            .notification-dropdown {
              li {
                + li {
                  p {
                    a {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .product-box {
      .product-img {
        .product-hover {
          ul {
            li {
              &:hover {
                background-color: $primary-color;
              }
            }
          }
        }
      }
    }
    .categories {
      ul {
        li {
          &:hover {
            a {
              color: $primary-color;
            }
          }
        }
      }
    }
    // dashboard css
    .goal-view {
      ul {
        &.goal-list {
          border-color: $dark-card-border;
          li {
            border-color: $dark-card-border;
          }
        }
      }
    }
    .dashboard-default {
      .appointment-detail {
        .card-body {
          .table {
            tr {
              td {
                background-color: $dark-card-background;
                .d-flex {
                  a {
                    span {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .profile-greeting {
        .greeting-user {
          h4 {
            a {
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    h4 a
    //general-widget
    .default-datepicker {
      .datepicker-inline {
        .datepicker {
          background: $dark-card-background;
          .datepicker--nav-title {
            color: $dark-all-font-color;
            i {
              color: $dark-all-font-color;
            }
          }
          .datepicker--content {
            .datepicker--days {
              .datepicker--days-names,
              .datepicker--cells {
                .datepicker--day-name,
                .datepicker--cell-day {
                  color: $dark-all-font-color;

                  &.-selected- {
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
    .static-widget {
      h6 {
        color: $dark-all-font-color;
      }
    }
    .widget-joins {
      .widget-card {
        border-color: $dark-card-border;
      }
    }
    .widget-feedback {
      ul {
        border-color: $dark-card-border;
        li.list-group-item {
          &:nth-child(n + 2) {
            border-color: $dark-card-border;
          }
        }
      }
    }
    .customers {
      ul {
        li {
          img {
            border: 2px solid $dark-card-background;
          }
        }
      }
    }
    .jkanban-container {
      .card-header {
        p {
          color: $dark-small-font-color;
        }
      }
    }
    .react-kanban-column{
      background-color: $dark-body-background;
      .react-kanban-card{
        background-color: $dark-card-background;
        .react-kanban-card__title{
          border-bottom: 1px solid $dark-card-border;
        }
      }
    }
    .kanban-board-header {
      background-color: $dark-body-background !important;
      border-bottom: 1px solid $dark-card-background !important;
    }
    .kanban-container {
      .kanban-item {
        .kanban-box {
          border: 1px solid rgba($primary-color, 0.15);
          background-color: $dark-body-background;
        }
      }
    }
    .jkanban-container {
      .kanban-container {
        .kanban-item {
          .kanban-box {
            background-color: $dark-card-background !important;
            color: $dark-all-font-color;
          }
        }
      }
    }
    .jkanban-container {
      .kanban-container {
        .kanban-drag {
          > div {
            > div {
              .react-kanban-column {
                background-color: $dark-body-background;
              }
            }
          }
        }
      }
    }
    .react-kanban-card-adder-button{
      border: 1px solid $dark-card-border;
      color: $dark-all-font-color;
      &:hover{
        background-color: $dark-card-background;
      }
    }
    .product-page-main {
      .border-tab.nav-tabs {
        border-bottom: 0 !important;
      }
    }
    .custom-profile {
      .card-social {
        li {
          border-color: $dark-card-border;
        }
      }
      .card-footer {
        > div {
          [class*="col-"] {
            &:hover {
              h6 {
                color: $dark-all-font-color;
              }
            }
          }
          h6 {
            color: $dark-small-font-color;
          }
          div {
            + div {
              border-color: $dark-card-border;
            }
          }
        }
      }
    }
    .activity-media {
      .d-flex {
        &:nth-child(n + 2) {
          border-color: $dark-card-border;
        }
        .flex-grow-1 {
          span {
            color: $dark-small-font-color;
          }
        }
      }
    }
    .social-widget-card {
      h6 {
        color: $dark-all-font-color;
      }
    }
    // project create
    .project-cards {
      .project-list {
        .card {
          .border-tab.nav-tabs {
            border-bottom: unset !important;
          }
        }
      }
    }
    .tilt-showcase {
      .card-body {
        pre {
          background-color: $pre-tag-bg-color;
          code {
            background-color: $pre-tag-bg-color;
          }
        }
      }
    }
    .btn-light.disabled {
      color: $black !important;
    }
    // daterangepicker css start
    .daterangepicker {
      &:before {
        border-bottom-color: $dark-card-background;
      }
      .ranges {
        li {
          background-color: $dark-body-background;
          border-color: $dark-card-background;
          color: $dark-gray;
        }
      }
      .daterangepicker_input {
        .form-control {
          background-color: $dark-body-background;
          border-color: $dark-card-background !important;
        }
      }
      .calendar-time {
        select {
          border-color: $dark-card-background;
          background-color: $dark-body-background;
        }
      }
      &.dropdown-menu {
        background-color: $dark-card-background;
        box-shadow: 0 0 14px $dark-body-background;
      }
      .calendar-table {
        background-color: $dark-card-background;
        border-color: $dark-card-background;
      }
      &.ltr {
        td {
          &.in-range,
          &.off {
            background-color: $dark-body-background;
            color: $white;
          }
        }
      }
    }
    .daterangepicker {
      select.monthselect,
      select.yearselect {
        background-color: $dark-body-background;
        border-color: $dark-card-border;
      }
    }
    .datepicker--pointer {
      background-color: $dark-card-border;
      border-color: $dark-card-border;
    }
    .datepicker--nav {
      border-color: $dark-card-border;
    }
    .datepicker--cell-day {
      color: $dark-all-font-color;
    }
    .datepicker {
      background-color: $dark-card-background;
      .datepicker--cell {
        color: $white;
      }
    }
    .stepwizard {
      .stepwizard-row {
        .stepwizard-step {
          .btn-light {
            background-color: $dark-body-background !important;
            &.btn-primary {
              background-color: $primary-color !important;
            }
          }
        }
      }
    }
    // form wizard
    .f1 {
      .f1-steps {
        .f1-progress {
          background-color: $dark-card-border;
        }
        .f1-step {
          .f1-step-icon {
            background-color: $dark-card-border;
            color: $dark-all-font-color;
          }
          &.active {
            .f1-step-icon {
              background-color: $primary-color;
            }
          }
        }
      }
    }
    // clock picker
    .clockpicker-popover {
      .popover-title {
        background-color: $dark-card-background;
        border-color: $dark-card-border;
      }
    }
    .popover {
      &.bottom {
        > .arrow {
          &:after {
            border-bottom-color: $dark-card-border;
          }
        }
      }
      &.top {
        > .arrow {
          &:after {
            border-top-color: $dark-card-border;
          }
        }
      }
      &.left {
        > .arrow {
          border-left-color: $dark-card-border;
          &:after {
            border-left-color: $dark-card-border;
          }
        }
      }
    }
    .clockpicker-plate {
      background-color: $dark-body-background;
      border: 1px solid $dark-card-border;
    }
    .clockpicker-tick {
      &:hover {
        background-color: $dark-card-background;
        color: $white;
      }
    }
    // tooltip css start
    .tooltip {
      &.bs-tooltip-top {
        .tooltip-arrow {
          &:before {
            border-top-color: $dark-card-inbox;
          }
        }
      }
      &.bs-tooltip-bottom {
        .tooltip-arrow {
          &:before {
            border-bottom-color: $dark-card-inbox;
            border-top-color: $transparent-color;
          }
        }
      }
      &.bs-tooltip-start {
        .tooltip-arrow {
          &:before {
            border-left-color: $dark-card-inbox;
            border-top-color: $transparent-color;
          }
        }
      }
      &.bs-tooltip-end {
        .tooltip-arrow {
          &:before {
            border-right-color: $dark-card-inbox;
            border-top-color: $transparent-color;
          }
        }
      }
      .tooltip-inner {
        background-color: $dark-card-inbox;
        color: $white;
      }
      .tooltip-arrow {
        &:before {
          border-top-color: $dark-card-inbox;
        }
      }
    }
    .nav.nav-tabs.border-tab.nav-primary {
      .nav-item {
        .nav-link.active {
          background-color: unset;
        }
      }
    }
    // tooltip css end
    .page-wrapper {
      .page-header {
        .header-wrapper {
          .left-header {
            .input-group {
              background-color: $dark-body-background;
              .form-control {
                color: $dark-small-font-color;
              }
              .input-group-prepend {
                .input-group-text {
                  i {
                    color: $white;
                  }
                }
              }
            }
          }
          .nav-right {
            > ul {
              > li {
                &:hover {
                  &:first-child {
                    background: none;
                  }
                }
              }
            }
            .profile-dropdown li span {
              color: $white;
            }
            .onhover-show-div:not(.profile-dropdown) {
              li {
                &:first-child {
                  background-color: $dark-card-background;
                }
              }
            }
          }
        }
      }
      .page-body-wrapper {
        .page-title {
          .breadcrumb {
            .breadcrumb-item {
              + .breadcrumb-item {
                &:before {
                  color: $dark-all-font-color;
                }
              }
            }
          }
        }
      }
    }
    .customizer-contain {
      color: $dark-body-background;
      .customizer-body {
        .main-layout {
          .box-layout {
            background-color: $white;
          }
        }
      }
    }
    .login-card {
      .btn-showcase .btn {
        background-color: $dark-card-background !important;
        color: $white;
        border-color: $dark-card-background !important;
      }
      .login-main {
        box-shadow: 0 0 37px rgba($white, 0.05);
        background-color: $dark-body-background;
        .theme-form {
          input {
            background-color: $dark-card-background !important;
          }
          .or {
            &:before {
              background-color: $dark-card-background;
            }
          }
          .checkbox label::before {
            background-color: $dark-card-background !important;
            border-color: $dark-card-background !important;
          }
          .login-social-title {
            h6 {
              background-color: $dark-body-background;
            }
            &::before {
              background-color: $dark-card-border;
            }
          }
          ul.login-social {
            li {
              border-color: $dark-card-border;
            }
          }
        }
      }
    }
    .H_ui {
      ~ div {
        background-color: $dark-body-background !important;
      }
    }
    .swal-icon--success:after,
    .swal-icon--success:before {
      background: $dark-card-background;
    }
    .swal-icon--success__hide-corners {
      background-color: $dark-card-background;
    }
    .note-editor.note-frame {
      .note-status-output {
        border-top: 1px solid $dark-card-background;
      }
      .note-statusbar {
        border-top: 1px solid $dark-card-background;

        .note-resizebar {
          background-color: $dark-card-background;
        }
      }
    }
    .light-font {
      color: rgba(255, 255, 255, 70%);
    }
    .page-link {
      background-color: $dark-body-background;
      border: 1px solid $dark-card-border;
    }
    .page-item {
      &.disabled {
        .page-link {
          background-color: $dark-card-border;
          border-color: $dark-card-border;
        }
      }
    }
    .b-r-light {
      border-right: 1px solid $dark-card-border !important;
    }
    .history-details {
      .d-flex {
        border-top: 1px solid $dark-card-border;
      }
    }
    #right-history {
      background-color: $dark-card-background;
      box-shadow: 0 0 9px $dark-body-background;
      h6 {
        span {
          a {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .translate_wrapper.active .more_lang {
      &:before {
        border-bottom: 7px solid $dark-card-background;
      }
      .lang {
        border-bottom: 1px solid $dark-card-border;
        &:last-child {
          border-bottom: unset;
        }
      }
    }
    .apexcharts-point-annotations {
      text {
        fill: $dark-small-font-color;
      }
    }
    .page-wrapper {
      .page-header {
        .header-wrapper {
          .nav-right {
            > ul {
              > li {
                &:nth-child(n + 2) {
                  &:hover {
                    svg {
                      stroke: $dark-all-font-color !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .page-body-wrapper {
        background-color: $dark-body-background;
      }
      &.compact-wrapper {
        .page-body-wrapper {
          div.sidebar-wrapper {
            .sidebar-main {
              .sidebar-links {
                .sidebar-link.active {
                  svg {
                    stroke: var(--theme-default);
                  }
                }
                li {
                  a {
                    span {
                      color: $dark-all-font-color;
                    }
                    svg {
                      stroke: $dark-all-font-color;
                    }
                  }
                }
                .simplebar-wrapper {
                  .simplebar-mask {
                    .simplebar-content-wrapper {
                      .simplebar-content {
                        > li {
                          .sidebar-link {
                            &.active {
                              svg {
                                color: var(--theme-default);
                              }
                            }
                          }
                          .mega-menu-container {
                            .mega-box {
                              .link-section {
                                .submenu-title {
                                  h5 {
                                    color: $dark-all-font-color;
                                  }
                                }
                                .submenu-content {
                                  &.opensubmegamenu {
                                    li {
                                      a {
                                        color: $dark-all-font-color;
                                        font-weight: 400;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            &::after {
                              background-color: $light-all-font-color;
                            }
                          }
                          .sidebar-submenu {
                            li {
                              a {
                                color: $dark-all-font-color;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .sidebar-list {
                  ul.sidebar-submenu {
                    li {
                      a {
                        span {
                          color: $dark-all-font-color;
                        }
                        &.active {
                          color: var(--theme-default);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .according-menu {
            i {
              color: $dark-all-font-color;
            }
          }
        }
      }
      &.horizontal-wrapper {
        .page-body-wrapper {
          .sidebar-wrapper {
            background: $dark-body-background;
            border-top: 2px solid $dark-body-background;
            .sidebar-main {
              .sidebar-links {
                .simplebar-wrapper {
                  .simplebar-mask {
                    .simplebar-content-wrapper {
                      .simplebar-content {
                        > li {
                          > a {
                            color: $dark-all-font-color;
                          }
                          .sidebar-submenu {
                            background: $dark-card-background;
                            li {
                              a {
                                color: $dark-all-font-color;
                                &.active,
                                &:hover {
                                  color: var(--theme-default);
                                }
                              }
                              .nav-sub-childmenu {
                                background: $dark-card-background;
                              }
                            }
                          }
                          .mega-menu-container {
                            background: $dark-card-background;
                            .mega-box {
                              .link-section {
                                .submenu-content {
                                  li {
                                    a {
                                      color: $dark-all-font-color;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .left-arrow,
              .right-arrow {
                background-color: $dark-body-background;
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
      .page-header {
        .header-wrapper {
          .search-full {
            background-color: $dark-card-background;
            input {
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .left-header {
      .mega-menu-container {
        background-color: $dark-card-background;
        border-top: 1px solid $dark-card-background;
        .mega-box {
          + .mega-box {
            border-left: 1px solid $dark-card-border;
          }
        }
      }
      .mega-menu div > div a {
        color: rgba($white, 0.7);
      }
      .header-level-menu {
        background-color: $dark-card-background;
        > li {
          .header-level-sub-menu {
            background-color: $dark-card-background;
          }
          a {
            color: rgba($white, 0.7);
          }
          svg {
            stroke: rgba($white, 0.7);
          }
        }
      }
    }
    .kanban-board-header {
      background-color: $dark-body-background !important;
      border-bottom: 1px solid $dark-card-border !important;
    }
    .kanban-board-header.bg-info {
      background-color: $info-color !important;
      ~ .kanban-drag {
        background-color: rgba($info-color, 0.3) !important;
      }
    }
    .kanban-board-header.bg-warning {
      background-color: $warning-color !important;
      ~ .kanban-drag {
        background-color: rgba($warning-color, 0.3) !important;
      }
    }
    .kanban-board-header.bg-success {
      background-color: $success-color !important;
      ~ .kanban-drag {
        background-color: rgba($success-color, 0.3) !important;
      }
    }
    .kanban-board .kanban-drag {
      background: $dark-body-background !important;
    }
    .kanban-container {
      .kanban-item {
        .kanban-box {
          border-color: $dark-card-border;
          background-color: $dark-body-background;
        }
      }
    }
    .project-box {
      border: 1px solid rgba($primary-color, 0.15);
      background-color: $dark-body-background;
    }
    .add-product {
      .product-info {
        .product-group {
          border: 1px solid $dark-card-border;
        }
      }
    }
    .quick-file {
      li {
        .quick-box {
          border: 1px solid $dark-card-border;
        }
      }
    }
    .file-content {
      .folder {
        .folder-box {
          background-color: $dark-body-background;
        }
      }
    }
    .files-content {
      li {
        .files-list {
          .file-left {
            border: 1px solid $dark-card-border;
          }
        }
      }
    }
    .file-content {
      .form-control-plaintext {
        color: $dark-all-font-color;
        background-color: $dark-card-background !important;
        &:focus {
          color: $dark-all-font-color;
        }
      }
      .form-inline {
        border-color: $dark-card-border;
      }
      .files {
        .file-box {
          border-color: $dark-card-border;
          .file-top {
            background-color: $dark-body-background;
            border-color: $dark-border-color;
          }
        }
      }
      .folder {
        .folder-box {
          border-color: $dark-card-border;
          &.list-group-item{
            border: 1px solid $dark-card-border;
          }
        }
      }
    }
    .custom-card{
      .card-social{
        border-top: 0;
      }
    }
    .my-gallery.gallery-with-description{
      img{
        background-color: $dark-card-background !important;
      }
    }
    .file-manager {
      > h6 {
        color: $dark-all-font-color;
      }
    }
    .file-sidebar {
      .pricing-plan {
        border-color: $dark-card-border;
        h6 {
          color: $dark-all-font-color;
        }
        p {
          color: $dark-small-font-color;
        }
      }
    }
    .calendar-basic {
      .tui-full-calendar-popup {
        .tui-full-calendar-button.tui-full-calendar-section-private {
          background-color: $dark-body-background;
        }
        .tui-full-calendar-popup-section-item {
          background-color: $dark-body-background;
        }
        .tui-calendar-title-today {
          background-color: $dark-body-background;
        }
        .tui-full-calendar-confirm {
          span {
            background-color: $primary-color;
          }
        }
      }
    }
    #menu .sidebar-list .btn-default {
      color: rgba($dark-all-font-color, 0.2);
    }
    .tui-full-calendar-timegrid-hour {
      background-color: $dark-card-background;
      color: $white !important;
    }
    .tui-full-calendar-layout {
      * {
        color: $dark-all-font-color !important;
        border-color: $dark-card-border !important;
      }
    }
    .tui-full-calendar-timegrid-gridline {
      border-bottom: 1px solid $dark-card-border !important;
    }
    .tui-full-calendar-time-date,
    .tui-full-calendar-weekday-grid-line,
    .tui-full-calendar-left,
    .tui-full-calendar-timegrid-timezone {
      border-right-color: $dark-card-border !important;
    }
    .tui-full-calendar-popup {
      color: $theme-body-font-color;
    }
    #menu {
      .btn-default {
        color: $dark-all-font-color;
        &:hover {
          color: $theme-body-font-color;
        }
      }
      .dropdown-menu {
        color: $dark-all-font-color;
      }
    }
    .tui-full-calendar-dayname-container,
    .tui-full-calendar-splitter {
      border-top-color: $dark-card-border !important;
      border-bottom-color: $dark-card-border !important;
    }
    span.tui-full-calendar-dayname-date-area {
      color: $white !important;
    }
    .tui-full-calendar-layout {
      background-color: $dark-card-background !important;
    }
    // starter-kit index page
    .right-header {
      .input-group {
        background-color: $dark-body-background;
        .input-group-prepend {
          .input-group-text {
            &::before {
              background-color: $light-all-font-color;
            }
            i {
              color: $white;
            }
          }
        }
      }
    }
    .table-responsive {
      .hover.dataTable {
        tbody {
          tr.odd {
            &:hover {
              background-color: $dark-body-background !important;
            }
          }
        }
      }
    }
    // typeahead
    .typeahead{
      .theme-form{
        .rbt.has-aux{
          .rbt-aux{
            .rbt-close.btn-close{
              .rbt-close-content{
                top: 14px !important;
              }
            }
          }
          .rbt-close.btn-close{
            .rbt-close-content{
              top: 2px !important;
            }
          }
        }
      }
    }
    .dashboard-2 {
      .best-seller {
        table {
          tbody {
            tr {
              &:hover {
                td {
                  &:last-child {
                    span {
                      a {
                        color: $primary-color;
                      }
                    }
                  }
                }
              }
              td {
                .d-flex {
                  span {
                    a {
                      color: $dark-all-font-color;
                    }
                  }
                }
                &:last-child {
                  span {
                    a {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    table tbody tr td:last-child span
    // index page css end
    .blockquote {
      border-left: 4px solid $dark-card-border;
    }
    .figure {
      &.text-end {
        blockquote {
          border-right-color: $dark-card-border;
        }
      }
    }
    .contacts-tabs {
      .nav-pills {
        border-right: 1px solid $dark-card-border;

        .nav-link {
          + .nav-link {
            border-top: 1px solid $dark-card-border;
          }
        }
      }
    }
    .list-persons {
      .profile-mail {
        .d-flex {
          .flex-grow-1 {
            ul {
              li {
                + li {
                  border-left: 2px solid $dark-card-border;
                }
              }
            }
          }
        }
      }
    }
    .product-wrapper {
      &.sidebaron {
        .product-sidebar {
          .filter-section {
            .card {
              .left-filter {
                background-color: $dark-card-background;
              }
            }
          }
        }
      }
    }
    .apexcharts-gridline {
      stroke: $dark-card-border;
    }
    .apexcharts-tooltip-title,
    .apexcharts-tooltip-series-group,
    .apexcharts-tooltip.light,
    .apexcharts-menu-item {
      color: $white;
    }
    // peity chart css
    .small-donut,
    .donut-peity2,
    .pie-peity {
      ~ svg,
      svg {
        path {
          &:nth-child(even) {
            fill: $dark-body-background;
          }
        }
      }
    }
    // wish list page
    .prooduct-details-box {
      .d-block {
        .flex-grow-1 {
          outline: 1px solid $dark-card-border;
        }
      }
      .price {
        span {
          color: $dark-small-font-color;
        }
      }
    }
    .pricing-simple {
      .card-body {
        h3 {
          &::before {
            background-color: $dark-card-border;
          }
        }
      }
    }
    .cke_toolbar_separator {
      background-color: $dark-card-border;
    }
    .cke_toolgroup {
      a {
        &.cke_button {
          &:last-child {
            &::after {
              border-right: 1px solid $dark-card-border;
            }
          }
        }
      }
    }
    .cke_combo {
      &:after {
        border-right: 1px solid $dark-card-border;
      }
    }
    // cart page
    .order-history {
      table {
        .qty-box {
          .touchspin {
            background-color: $dark-card-background !important;
            border: 0 !important;
          }
        }
      }
      .product-name {
        h6 {
          color: $dark-small-font-color;
        }
      }
    }
    .order-history{
      .title-orders {
        background-color: $dark-body-background;
      }
      table{
        tr {
          td,
          th {
            border-top: none !important;
          }
        }
      }
      .rdt_Table{
        .rdt_TableBody{
          .rdt_TableRow{
            &:hover{
              background-color: $dark-body-background;
              border-bottom: 1px solid $dark-body-background;
              &:nth-child(even) {
                .rdt_TableCell {
                  background-color: $dark-body-background !important;
                }
              }
              &:nth-child(odd) {
                .rdt_TableCell {
                  background-color: $dark-body-background !important;
                }
              }
              .rdt_TableCell {
                background-color: $dark-body-background;
              }
            }
            &:nth-child(odd){
              .rdt_TableCell{
                &:first-child{
                  background-color: rgba($dark-card-background, 0.5);
                }
                &:nth-child(n+2){
                  background-color: $dark-card-background;
                }
              }
            }
            &:nth-child(even){
              .rdt_TableCell{
                &:first-child{
                  background-color: $dark-card-background;
                }
                &:nth-child(n+2){
                  background-color: rgba($dark-card-background, 0.5);
                }
              }
            }
          }
        }
      }
      .rdt_Table, .rdt_TableHeadRow, .rdt_TableRow, .rdt_Pagination{
        background-color: $dark-body-background;
        div, span {
          color: $dark-all-font-color;
        }
        button{
          fill: $dark-all-font-color;
        }
      }
    }
    .chat-box {
      .chat-menu {
        .nav-primary {
          .nav-link {
            &.active {
              background-color: unset;
            }
          }
        }
      }
    }
    .chat-box {
      .chat-menu {
        .people-list {
          .search {
            .theme-form {
              .form-group {
                i {
                  color: $dark-card-border;
                }
              }
            }
          }
        }
      }
    }
    // calender
    #menu {
      .dropdown {
        .btn-default {
          border-color: $dark-card-border;
          &:hover {
            color: $white;
          }
        }
      }
      .move-today,
      .move-day {
        border-color: $dark-card-border;
        &:hover {
          background-color: $dark-card-background;
          color: $white;
        }
      }
    }
    .page-wrapper {
      .page-header {
        .header-wrapper {
          .left-header {
            .left-menu-header {
              .app-list {
                ul {
                  background-color: $dark-body-background;
                  li {
                    &:hover {
                      a {
                        color: $secondary-color;
                      }
                    }
                    a {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
            }
          }
          .nav-right.right-header {
            .right-header {
              .input-group {
                .form-control {
                  color: $dark-small-font-color;
                }
              }
            }
            ul {
              li {
                .mode {
                  i {
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
    .left-header {
      .left-menu-header {
        ul.header-left {
          li {
            &:nth-child(n + 2) {
              &::before {
                background-color: $dark-card-border;
              }
            }
            ul.onhover-show-div {
              background-color: $dark-body-background;
              li.flyout-right {
                ul {
                  background-color: $dark-body-background;
                  li.flyout-right {
                    ul {
                      background-color: $dark-body-background;
                      li {
                        &:hover {
                          a {
                            color: $secondary-color;
                          }
                        }
                        a {
                          color: $dark-all-font-color;
                        }
                      }
                    }
                  }
                  li {
                    &:hover {
                      a {
                        color: $secondary-color;
                      }
                    }
                    a {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
              li {
                &:hover {
                  a {
                    color: $secondary-color;
                  }
                }
                a {
                  color: $dark-all-font-color;
                }
              }
            }
          }
        }
      }
    }
    .basic-card {
      .border-card,
      .border-bottom-light {
        border-bottom: 1px solid $dark-card-border;
      }
    }
    .tabbed-cardpage {
      .card {
        .card-body {
          .border-tab.nav-tabs {
            border-bottom: unset !important;
          }
        }
      }
    }
    .serchinput {
      .search-form {
        input {
          background-color: $dark-body-background;
          border: 1px solid $dark-card-background;
          color: $dark-all-font-color;
        }
      }
    }
    // dashboard 1
    .dashboard-default {
      .todo-timeline {
        .d-flex {
          .flex-grow-1 {
            .todo-font {
              color: $dark-all-font-color;
            }
          }
        }
      }
      .card-header {
        .d-flex {
          .badge-group {
            .badge {
              background-color: rgba($primary-color, 0.3);
            }
          }
        }
      }
      .profile-greeting {
        .d-sm-flex {
          .badge-group {
            .badge.badge-light-primary {
              background-color: rgba($primary-color, 0.3);
            }
          }
        }
      }
      .user-chat {
        .card-body {
          .input-group {
            .form-control {
              background-color: $transparent-color;
              border-left: 1px solid $dark-card-border !important;
              border: 0;
            }
          }
        }
        .chat-box {
          .right-chat {
            .message-main {
              p {
                background-color: $dark-body-background;
              }
            }
          }
        }
      }
      .onhover-show-div {
        ul {
          li {
            background-color: $dark-body-background;
            &:hover {
              a {
                color: $primary-color;
              }
            }
            a {
              color: $dark-small-font-color;
            }
          }
        }
      }
      .total-growth {
        .card-body {
          .growth-chart {
            .apexcharts-point-annotations {
              rect {
                fill: $transparent-color;
              }
            }
          }
        }
      }
      .appointment-detail {
        .card-body {
          .table {
            tr {
              &:hover {
                td {
                  color: $primary-color !important;
                  &:first-child {
                    span {
                      color: $dark-all-font-color;
                    }
                    p {
                      color: $primary-color;
                    }
                  }
                }
              }
              td {
                .d-flex {
                  .active-status {
                    border: 2px solid $dark-card-background;
                  }
                  p {
                    color: $dark-small-font-color;
                  }
                }
              }
            }
          }
        }
      }
      .our-earning {
        .card-footer {
          ul {
            li {
              + li {
                &::before {
                  background-color: $dark-card-border;
                }
              }
            }
          }
        }
      }
      .our-user {
        .card-body {
          ul {
            li {
              + li {
                &::before {
                  background-color: $white;
                }
              }
            }
          }
        }
      }
      .total-investment {
        .progress {
          .progress-colors {
            background-color: rgba($dark-card-border, 0.8);
          }
        }
      }
    }
    .page-wrapper {
      .page-body-wrapper {
        .page-body {
          .card:not(.email-body) {
            .card-body {
              .alert {
                hr {
                  border-top-color: $dark-card-border !important;
                  border-top: unset;
                }
              }
            }
          }
        }
      }
    }
    // ecommerce dashboard css start
    .dashboard-2 {
      .activity-timeline {
        .d-flex {
          .flex-grow-1 {
            ul.img-wrapper {
              li {
                background-color: $dark-body-background;
              }
            }
          }
        }
      }
      .order-card {
        .card-body {
          table {
            tr {
              &:hover {
                td {
                  color: $primary-color !important;
                  &:first-child {
                    .number-dot {
                      background-color: rgba($primary-color, 0.1);
                    }
                    .flex-grow-1 {
                      span {
                        color: $dark-all-font-color;
                      }
                    }
                  }
                }
              }
              td {
                &:first-child {
                  .number-dot {
                    background-color: $dark-body-background;
                  }
                }
              }
            }
          }
        }
      }
      .static-card {
        .card-footer {
          ul.d-xxl-flex {
            li {
              .flex-grow-1 {
                p {
                  color: $dark-small-font-color;
                }
              }
            }
          }
        }
      }
      .goal-view {
        .card-footer {
          ul {
            li {
              + li {
                &::before {
                  background-color: $white;
                }
              }
            }
          }
        }
      }
      .best-seller {
        .card-body {
          table {
            tr {
              &:hover {
                td {
                  color: $primary-color !important;
                  &:first-child {
                    .flex-grow-1 {
                      span {
                        color: $dark-all-font-color;
                      }
                      p {
                        color: $primary-color;
                      }
                    }
                  }
                  &:last-child {
                    span {
                      background-color: rgba($primary-color, 0.1);
                    }
                  }
                }
              }
              td {
                &:last-child {
                  span {
                    background-color: $dark-body-background;
                  }
                }
              }
            }
          }
        }
      }
      .product {
        .card-body {
          .table {
            tr {
              &:hover {
                td {
                  &:last-child {
                    color: $primary-color;
                  }
                }
              }
              td {
                .d-flex {
                  span {
                    a {
                      color: $dark-all-font-color;
                    }
                  }
                  p {
                    color: $dark-all-font-color;
                  }
                }
              }
            }
          }
        }
      }
      .card-body {
        .d-flex {
          .flex-grow-1 {
            p {
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .activity-timeline {
      .d-flex {
        .activity-line {
          background-color: rgba(255, 255, 255, 0.05);
        }
        .flex-grow-1 {
          p {
            color: $dark-all-font-color;
          }
        }
      }
    }
    [class*="activity-dot-"] {
      background-color: $dark-card-background;
    }
    .slick-slider {
      .slick-arrow {
        display: none !important;
      }
    }
    .rdt_Table {
      border: 1px solid $dark-border-color;
    }
    .rdt_TableHeadRow {
      background-color: $dark-card-background;
    }
    .rdt_TableCol,
    .rdt_TableCol_Sortable,
    .___react-data-table-allow-propagation___ {
      color: $dark-all-font-color;
    }
    .rdt_TableRow {
      &:nth-of-type(odd) {
        color: $dark-all-font-color;
        background-color: $dark-body-background;
      }
      &:nth-of-type(even) {
        color: $dark-all-font-color;
        background-color: $dark-card-background;
      }
    }
    .rdt_Pagination {
      background-color: $dark-card-background;
      border-top-color: $dark-border-color;
      color: $dark-all-font-color;
      button {
        color: $dark-all-font-color;
        fill: $dark-all-font-color;
        &:disabled {
          color: $dark-small-font-color;
          fill: $dark-small-font-color;
        }
      }
    }
    .rdt_TableCol_Sortable {
      &:hover {
        color: $dark-all-font-color;
      }
    }
    .rdt_TableHeader {
      background-color: $dark-body-background !important;
      > div {
        &:last-child {
          background-color: $dark-body-background !important;
          color: $dark-all-font-color;
        }
      }
    }
    .page-wrapper {
      .page-body-wrapper {
        .page-body {
          .mega-inline.plain-style {
            .card {
              border: none !important;
            }
          }
        }
      }
    }
    .animation-modal {
      .card-body {
        .modal {
          button.btn-close {
            background-color: $white !important;
          }
        }
      }
    }
    // user profile
    .user-profile {
      .follow {
        span {
          color: $dark-small-font-color;
        }
        ul.follow-list {
          border-top: 1px solid $dark-card-border;
          li {
            &:nth-child(n + 2) {
              border-left: 1px solid $dark-card-border;
            }
          }
        }
        .follow-num {
          color: $dark-all-font-color;
        }
      }
      .profile-header {
        .userpro-box {
          background-color: $dark-card-background;
          .icon-wrapper {
            background-color: $dark-body-background;
          }
          h4 {
            color: $dark-all-font-color;
          }
          h6 {
            color: $dark-small-font-color;
          }
        }
      }
    }
    // ecommerce dashboard css end
    .page-header {
      .header-wrapper {
        .nav-right {
          .chat-dropdown,
          .profile-dropdown {
            li {
              &:last-child {
                border-top: 1px solid $dark-card-border !important;
              }
            }
          }
        }
      }
    }
    $alert-name: primary, secondary, success, danger, warning, info, light, dark;
    $alert-color: $primary-color, $secondary-color, $success-color,
      $danger-color, $warning-color, $info-color, $light-color, $dark-color;
    @each $var in $alert-name {
      $i: index($alert-name, $var);
      .alert-#{$var} {
        background-color: rgba(nth($alert-color, $i), 0.8) !important;
        border-color: rgba(nth($alert-color, $i), 0.9) !important;
        color: $white;
        .progress {
          height: 5px;
          background-color: darken(nth($alert-color, $i), 1%);
          border-radius: 0;
        }
        .progress-bar {
          background-color: lighten(nth($alert-color, $i), 50%);
        }
      }
      .alert-#{$var}.inverse {
        background-color: $transparent-color !important;
        .btn-close {
          filter: brightness(1) invert(1);
        }
      }
      .alert-#{$var}.outline,
      .alert-#{$var}.outline-2x {
        .btn-close {
          filter: brightness(1) invert(1);
        }
      }
      .dismiss-text {
        .alert-dismissible {
          .btn-close {
            filter: unset;
            .bg-light {
              color: $theme-body-font-color !important;
            }
          }
        }
      }
    }
    .options {
      > div {
        border: 1px solid $dark-card-border;
      }
    }
    .was-validated {
      .custom-control-input {
        &:invalid {
          ~ .custom-control-label {
            &::before {
              background-color: $dark-body-background;
            }
          }
        }
      }
    }
    .pricing-simple {
      box-shadow: 1px 1px 2px 1px $dark-body-background !important;
    }
    .pricing-block {
      box-shadow: 0 0 10px 5px rgba($primary-color, 0.05) !important;
    }
    .search-page {
      ul {
        &.search-info {
          li {
            + li {
              border-left: 1px solid $dark-body-background;
              border-color: $dark-card-border;
            }
          }
        }
      }
    }
    .browse {
      .browse-articles {
        ul {
          li {
            h5 {
              color: $dark-small-font-color;
            }
          }
        }
        h6 {
          border-bottom: 1px solid $dark-card-border;
          color: $dark-all-font-color;
        }
      }
    }
    .job-search {
      .job-description {
        .theme-form {
          .row {
            div[class^="col-"] {
              .input-group {
                .datepicker-here {
                  border: 1px solid $dark-body-background;
                }
              }
            }
          }
        }
      }
    }
    .browse {
      .browse-articles {
        background-color: $dark-card-background;
      }
    }
    .calendar-wrap {
      .fc-unthemed {
        td,
        th {
          border-color: $dark-body-background;
        }
      }
    }
    .mega-inline,
    .mega-horizontal,
    .mega-vertical {
      .card {
        box-shadow: 1px 1px 7px 0 rgba($dark-card-border, 0.5) !important;
      }
      &.plain-style,
      &.border-style,
      &.offer-style {
        .card {
          box-shadow: none !important;
        }
      }
      &.border-style {
        .card {
          border: 1px solid $dark-card-border !important;
        }
      }
      &.offer-style {
        .card {
          border: 1px dashed $dark-card-border !important;
        }
      }
    }
    .setting-dot {
      .setting-bg {
        background-color: var(--theme-secondary);
      }
    }
    .bookmark.pull-right {
      border: none;
    }
    .onhover-dropdown {
      &:hover {
        .onhover-show-div {
          &::before {
            border-bottom: 7px solid $dark-body-background;
          }
        }
      }
    }
    // popover css start
    .popover {
      &.bs-popover-bottom {
        .popover-arrow {
          &:after {
            border-bottom-color: $dark-body-background;
            border-right-color: $transparent-color;
          }
        }
      }
      &.bs-popover-top {
        .popover-arrow {
          &:after {
            border-right-color: $transparent-color;
            border-top-color: $dark-body-background;
          }
        }
      }
      &.bs-popover-start {
        .popover-arrow {
          &:after {
            border-left-color: $dark-body-background;
          }
        }
      }
      background-color: $dark-body-background;
      .popover-header {
        background-color: rgba($primary-color, 0.1);
      }
      .popover-arrow {
        &:after {
          border-right-color: $dark-body-background;
        }
      }
      .popover-body {
        color: rgba(255, 255, 255, 0.6);
      }
      code {
        background-color: unset;
      }
    }
    // apex chart css start
    .apex-chart-container {
      .apexcharts-legend {
        .apexcharts-legend-series {
          span {
            color: $dark-all-font-color !important;
          }
        }
      }
    }
    .apexcharts-canvas {
      svg {
        .apexcharts-title-text {
          fill: $white;
        }
        .apexcharts-subtitle-text {
          fill: $white;
        }
        .apexcharts-yaxis {
          .apexcharts-yaxis-texts-g {
            .apexcharts-yaxis-label {
              fill: $white;
            }
          }
        }
        .apexcharts-xaxis {
          .apexcharts-xaxis-texts-g {
            .apexcharts-xaxis-label {
              fill: $white;
            }
          }
        }
        .apexcharts-legend-series {
          span {
            color: $dark-all-font-color !important;
          }
        }
        .apexcharts-datalabels-group {
          .apexcharts-datalabel-label {
            fill: $white;
          }
          .apexcharts-datalabel-value {
            fill: $white;
          }
        }
      }
    }
    // apex chart css end
    .Typeahead-menu {
      background-color: $dark-body-background;
    }
    .ecommerce-widget {
      border: 1px solid $dark-border-color;
    }
    .bookmark {
      ul {
        li {
          .search-form {
            .form-control-search {
              input {
                background-color: $dark-body-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
              &:before {
                background: $dark-border-color;
              }
            }
          }
        }
      }
    }
    .order-history {
      table {
        thead {
          tr {
            th {
              color: $primary-color !important;
            }
          }
        }
      }
    }
    // cart css start
    .cart {
      .qty-box {
        .input-group {
          .btn {
            background-color: #2e3648 !important;
            border-color: $dark-card-border !important;
          }
        }
      }
    }
    // checkout css start
    .checkout {
      .checkout-details {
        background-color: $dark-body-background;
        border: 1px solid $dark-card-border;
        padding: 40px;
        .animate-chk {
          .radio_animated {
            &:after {
              border: 2px solid $dark-card-background;
            }
          }
        }
      }
    }
    // checkout css end
    .order-box {
      .title-box {
        color: #bfc2c6;
        border-bottom: 1px solid $dark-card-border;
      }
      .qty {
        li {
          color: #bfc2c6;
          span {
            color: #bfc2c6;
          }
        }
        border-bottom: 1px solid $dark-card-border;
      }
      .sub-total {
        li {
          color: #bfc2c6;
        }
        .shipping-class {
          border-color: $dark-card-border;
        }
      }
      .total {
        li {
          color: #bfc2c6;
        }
      }
    }
    .shopping-checkout-option {
      .checkbox_animated {
        &:after {
          border: 2px solid $dark-card-background;
        }
      }
    }
    .animate-chk {
      .radio_animated {
        &:after {
          border: 2px solid $dark-card-border;
        }
      }
    }
    .custom-scrollbar::-webkit-scrollbar-thumb {
      background-color: rgba(41, 50, 64, 0.52);
    }
    .product-box {
      .product-img {
        .product-hover {
          ul {
            li {
              .btn {
                color: $dark-small-font-color;
              }
              &:hover {
                .btn {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
    .radio_animated,
    .checkbox_animated {
      &:after {
        background: $dark-body-background;
        border: 2px solid $dark-body-background;
      }
    }
    .slider-product {
      border-top: 1px solid $dark-border-color;
      border-bottom: 1px solid $dark-border-color;
    }
    .square-product-setting {
      .icon-grid {
        background-color: $dark-card-background;
        svg {
          color: $dark-all-font-color;
        }
      }
    }
    .flot-chart-container {
      .legend {
        .table {
          tbody {
            background-color: $dark-card-background;
            color: $white;

            .legendLabel {
              padding-left: 5px;
            }
          }
        }
      }
    }
    .nav-dark,
    .nav-pills.nav-dark {
      .nav-link {
        color: $white;
      }
    }
    .loader-wrapper {
      background-color: $dark-body-background;
      .loader {
        background-color: $dark-body-background !important;
      }
    }
    .page-wrapper {
      /* Main Header start */
      .page-header {
        .translate_wrapper {
          &.active {
            .more_lang {
              background-color: $dark-body-background;
            }
          }
        }
        form {
          background-color: $dark-body-background;
        }
        .header-wrapper {
          background-color: $dark-card-background;
          border-color: $dark-card-border;
          .nav-right {
            > ul {
              > li {
                svg {
                  stroke: $white;
                }
              }
            }
            &.right-header {
              > ul {
                > li {
                  .profile-media {
                    .flex-grow-1 {
                      p {
                        color: $dark-all-font-color;
                      }
                    }
                  }
                }
              }
            }
            .nav-menus {
              li {
                .d-flex {
                  .flex-grow-1 {
                    .txt-dark {
                      color: $dark-all-font-color !important;
                    }
                  }
                }
                .onhover-show-div {
                  background-color: $dark-body-background;
                  box-shadow: 0 0 14px $dark-body-background;
                  li {
                    border-color: $dark-card-border;
                  }
                  p {
                    color: $dark-all-font-color;
                  }
                  &:before {
                    border-bottom: 7px solid $dark-body-background;
                  }
                  &:after {
                    border-bottom: 7px solid $dark-body-background;
                  }
                }
                .notification-dropdown.onhover-show-div {
                  li {
                    span {
                      color: $dark-small-font-color;
                    }
                  }
                  .bg-light {
                    background-color: #282e38 !important;
                    color: $dark-all-font-color !important;
                  }
                }
              }
            }
            .profile-dropdown {
              li {
                a {
                  color: $dark-all-font-color;
                  svg {
                    path,
                    line,
                    circle {
                      color: $dark-all-font-color !important;
                    }
                  }
                  &:hover {
                    color: var(--theme-default);
                    svg {
                      line,
                      path,
                      circle {
                        color: var(--theme-default) !important;
                      }
                    }
                  }
                }
              }
            }
            > ul {
              > li {
                .d-flex {
                  .flex-grow-1 {
                    .txt-dark {
                      color: $dark-all-font-color !important;
                    }
                  }
                }
                .onhover-show-div {
                  background-color: $dark-body-background;
                  box-shadow: 0 0 2px 2px $dark-card-background;

                  &:before {
                    border-bottom: 7px solid $dark-body-background;
                  }

                  &:after {
                    border-bottom: 7px solid $dark-body-background;
                  }
                }
                .notification-dropdown.onhover-show-div {
                  li {
                    border-color: $dark-card-border;

                    span {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
            }
            .chat-dropdown {
              li {
                color: $white;
                border-color: $dark-card-background;
              }
            }
          }
          .left-header {
            ul {
              li {
                .search-form {
                  input {
                    &::placeholder {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
            }
          }
        }
        .header-logo-wrapper {
          background-color: $dark-card-background;
          .logo-wrapper {
            a {
              .image-dark {
                display: none;
              }
              .image-light {
                display: block;
              }
            }
          }
        }
      }
      .left-header {
        .left-menu-header {
          ul.header-left {
            li {
              span {
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
      /* Main Header ends */
      .page-body-wrapper {
        /* body start*/
        .page-body {
          .topper-lists {
            table {
              tbody {
                tr {
                  td {
                    p {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
            }
          }
          .header-small {
            color: $dark-small-font-color;
          }

          .statistics {
            p {
              color: $dark-small-font-color;
            }
          }
          .feather-main,
          .professor-table {
            .flex-grow-1,
            .professor-block {
              p {
                color: $dark-small-font-color;
              }
            }
          }
          .logs-element {
            span {
              + span {
                color: $dark-small-font-color;
              }
            }
          }
          .progress-block {
            .progress-title {
              span {
                + span {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .ecommerce-icons {
            div {
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .new-users,
          .recent-notification {
            .d-flex {
              .flex-grow-1 {
                p {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .progress-media {
            .d-flex {
              .flex-grow-1 {
                span {
                  color: $dark-small-font-color;
                }
              }
            }
            .progress-change {
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .topper-lists {
            table {
              tbody {
                tr {
                  td {
                    border-top: none !important;
                    border-bottom: 1px solid $dark-card-border;
                  }
                  &:last-child {
                    td {
                      border-bottom: none;
                    }
                  }
                }
              }
            }
          }
          .notifiaction-media {
            .d-flex {
              .flex-grow-1 {
                .circle-left {
                  border: 5px solid #334154;
                }
              }
            }
          }
          .upcoming-event {
            .upcoming-innner {
              border-bottom: 1px solid $dark-border-color;

              &:last-child {
                border-bottom: none;
              }
            }
          }
          .professor-table {
            table {
              tbody {
                tr {
                  td {
                    border-top: none !important;
                  }
                }
              }
            }
          }
          .number-widgets {
            .d-flex {
              .flex-grow-1 {
                h6 {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .activity {
            .d-flex {
              .flex-grow-1 {
                h6 {
                  span {
                    color: $dark-small-font-color;
                  }
                }
              }
            }
          }
          .card.custom-card {
            .card-header {
              border-bottom: none !important;
            }
          }
        }
        .custom-card {
          .card-header {
            img {
              background-color: $black;
              opacity: 0.8;
            }
          }
          .profile-details {
            h4 {
              color: $dark-all-font-color;
              a {
                color: $dark-all-font-color;
              }
            }
          }
        }
        .page-title {
          .row {
            h3 {
              small {
                color: $dark-small-font-color;
              }
            }
          }
          .breadcrumb {
            li {
              color: $dark-all-font-color;
            }
            .breadcrumb-item {
              a {
                svg {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .breadcrumb-item {
            &.active {
              color: $dark-small-font-color;
            }
          }
        }
        .page-body {
          background-color: $dark-body-background;
          .default-according {
            .card:not(.email-body) {
              border: none;
            }
          }
          .card:not(.email-body) {
            background-color: $dark-card-background;
            border: 1px solid $dark-card-border !important;
            .chart-block {
              #bar-chart2 {
                svg {
                  > rect {
                    fill: $dark-card-background;
                  }
                  > g {
                    text {
                      fill: $dark-all-font-color;
                    }
                  }
                }
              }
              .word-tree {
                svg {
                  > g {
                    > rect {
                      fill: $dark-card-background;
                    }

                    > text {
                      fill: $dark-all-font-color;
                    }
                  }
                }
              }
            }
            .card-header {
              background-color: $transparent-color;
              > span {
                color: $dark-all-font-color;
              }
              h1,
              h2,
              h3,
              h4,
              h5,
              h6 {
                color: $dark-all-font-color;
              }
              .card-header-right {
                background-color: $dark-card-background;

                i {
                  color: $dark-all-font-color;

                  &.fa-cog {
                    color: var(--theme-default);
                  }
                }
              }
            }
            .alert-dark {
              color: $dark-small-font-color;

              a {
                color: $dark-small-font-color;
              }
            }
            .alert-light.outline,
            .alert-light.outline-2x,
            .alert-light.inverse {
              color: $dark-all-font-color;
            }
            #animation-box {
              .animate-widget {
                p {
                  color: $dark-small-font-color !important;
                }
              }
            }
            .grid-item {
              img {
                background-color: $dark-card-background;
                border: 1px solid $dark-card-border;
              }
            }
            .line {
              color: $dark-all-font-color;
            }
            thead,
            tbody,
            tfoot,
            tr,
            td,
            th {
              border-color: $dark-card-border;
            }
            .table {
              th,
              td {
                color: $dark-all-font-color;
              }
              thead {
                th {
                  border-bottom: 1px solid $dark-card-border;
                }
                .border-bottom-primary {
                  th {
                    border-bottom: 1px solid var(--theme-default);
                  }
                }
              }
              .table-primary,
              .table-secondary,
              .table-success,
              .table-info,
              .table-warning,
              .table-light,
              .table-danger {
                th,
                td {
                  color: $dark-card-background;
                }
              }
              .bg-primary,
              .bg-secondary,
              .bg-success,
              .bg-info,
              .bg-warning,
              .bg-danger {
                color: $white;
              }
              .bg-light {
                color: $black;
              }
              .thead-light {
                th {
                  color: $black;
                }
              }
              tbody {
                .border-bottom-primary {
                  th,
                  td {
                    border-bottom: 1px solid var(--theme-default);
                  }
                }
              }
            }
            .table[class*="bg-"] {
              th,
              td {
                color: $white;
              }
            }
            .table-striped {
              tbody {
                tr {
                  &:nth-of-type(odd) {
                    --bs-table-accent-bg: rgba(0, 0, 0, 0.05);
                    &:hover {
                      th,
                      td {
                        color: $white;
                      }
                    }
                  }
                }
              }
            }
            .table-double,
            .table-dotted,
            .table-dashed {
              border-left-color: $dark-card-border;
              border-right-color: $dark-card-border;
            }
            .table-bordered {
              border-color: $dark-card-border !important;

              td,
              th {
                border-color: $dark-card-border !important;
              }
            }
            .table-inverse {
              tbody {
                tr {
                  &:nth-of-type(odd) {
                    &:hover {
                      td {
                        color: $dark-all-font-color;
                      }
                    }
                  }
                }
              }
            }
            .table-border-vertical {
              tr,
              th,
              td {
                border-right: 1px solid $dark-card-border;
              }
            }
            .table-styling {
              thead,
              tbody {
                th,
                td {
                  color: $white;
                }
              }
            }
            .card-footer {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-card-border;
            }
            .switch {
              .switch-state {
                background-color: $dark-body-background;
              }
              input {
                &:checked {
                  + .switch-state {
                    background-color: var(--theme-default);
                  }
                }
              }
            }
            .bg-white {
              background-color: $white !important;
            }
            .b-l-light {
              border-left: 1px solid $dark-border-color !important;
            }
            .ct-grid {
              stroke: $dark-border-color;
            }
            .ct-label {
              color: $dark-small-font-color;
            }
            hr {
              border-top: 1px solid $dark-card-border;
            }
            .text-muted {
              color: $sidebar-submenu-font-color !important;
            }
            .calender-widget {
              .cal-date {
                background-color: $dark-card-background;
                border: 1px solid $dark-border-color;
              }
            }
            .contact-form {
              .theme-form {
                border: 1px solid $dark-card-border;
                .form-icon {
                  background-color: $dark-card-background;
                  border: 1px solid $dark-card-border;
                }
              }
            }
            .btn-outline-light,
            .btn-outline-dark,
            .btn-outline-light-2x {
              color: $white !important;
              border: 1px solid $dark-card-border;
            }
            .btn-outline-light {
              &:hover {
                color: $black !important;
              }
            }
            .border-right {
              border-right: 1px solid $dark-card-border !important;
            }
            .flot-chart-placeholder {
              .flot-text {
                color: $dark-all-font-color !important;
              }
              svg {
                text {
                  fill: $dark-all-font-color;
                }
              }
            }
            .chart-overflow {
              &#gantt_chart {
                svg {
                  g {
                    rect {
                      &:first-child {
                        fill: $dark-body-background;
                      }
                    }
                  }
                }
                rect {
                  &:nth-child(6) {
                    fill: $dark-body-background;
                  }
                }
              }
              &#line-chart,
              &#column-chart1,
              &#column-chart2 {
                rect {
                  fill: $dark-card-background;
                }
              }
              svg {
                > rect {
                  fill: $dark-card-background;
                }
                > g {
                  > g {
                    > g {
                      text {
                        fill: rgb(177, 177, 178);
                      }
                    }
                  }
                  > text {
                    fill: $dark-all-font-color;
                  }
                }
              }
            }
            .bar-chart-widget {
              .bottom-content {
                .num {
                  color: $dark-all-font-color;
                  .color-bottom {
                    color: $dark-all-font-color;
                  }
                }
              }
            }
            .b-r-light {
              border-left: 1px solid $dark-card-border !important;
            }
            .chart-container {
              .live-products,
              .turnover,
              .monthly,
              .uses {
                .ct-labels {
                  .ct-label {
                    color: $white;
                  }
                }
                .ct-grid {
                  stroke: $white;
                }
              }
              #browser-uses-chart,
              #website-visiter-chart {
                svg {
                  text {
                    fill: $dark-all-font-color;
                  }
                }
              }
            }
            .status-details {
              h4 {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
            .clipboaard-container {
              .form-control {
                background-color: $dark-body-background;
                color: $dark-small-font-color;
                border: 1px solid $dark-border-color;
              }
            }
            .img-thumbnail {
              background-color: $dark-body-background;
              border: 1px solid $dark-card-border;
            }
            .dataTables_wrapper {
              button {
                color: $black;
              }
              &.no-footer {
                .dataTables_scrollBody {
                  border-bottom: 1px solid $dark-body-background;
                }
              }
              .btn-danger,
              .btn-success,
              .btn-primary {
                color: $white;
              }
              .dataTables_length {
                select {
                  background-color: $dark-body-background;
                  color: $dark-all-font-color;
                  border: 1px solid $dark-border-color;
                  &:focus {
                    outline: none;
                  }
                }
              }
              .dataTables_length,
              .dataTables_filter,
              .dataTables_info,
              .dataTables_processing,
              .dataTables_paginate {
                color: $dark-all-font-color;
              }
              .dataTables_paginate {
                border: 1px solid $dark-card-border;
              }
              .dataTables_filter {
                input[type="search"] {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                  &:focus {
                    border-color: $dark-card-border;
                    outline: none;
                  }
                }
              }
              table.dataTable.display,
              table.dataTable.order-column.stripe {
                tbody {
                  tr {
                    background-color: $dark-card-background;
                    &:hover {
                      > .sorting_1 {
                        background-color: $dark-datatable-sorting;
                      }
                    }
                  }
                  tr.odd {
                    > .sorting_1 {
                      background-color: $dark-datatable-sorting;
                    }
                  }
                  tr.even {
                    > .sorting_1 {
                      background-color: $dark-datatable-sorting-even;
                    }
                  }
                }
              }
              table.dataTable {
                border: 1px solid $dark-card-border;
                thead {
                  th,
                  td {
                    border-bottom: 2px solid $dark-card-border;
                  }
                }
                input,
                select {
                  background-color: $dark-body-background;
                  color: $dark-all-font-color;
                  border: 1px solid $dark-border-color;
                }
                tbody {
                  tr {
                    background-color: $dark-card-background;
                  }
                  td.select-checkbox,
                  th.select-checkbox {
                    &:before {
                      border: 1px solid $dark-small-font-color;
                    }
                  }
                }
              }
              .table-striped {
                tbody {
                  tr {
                    &:nth-of-type(odd) {
                      --bs-table-accent-bg: #1d1e26;
                    }
                  }
                }
              }
              .dataTables_paginate {
                .paginate_button {
                  color: $dark-all-font-color !important;
                  &.current,
                  &:active {
                    border-color: var(--theme-default);
                  }
                }
                .paginate_button.disabled {
                  color: $dark-small-font-color !important;

                  &:hover,
                  :active {
                    color: $dark-small-font-color !important;
                  }
                }
              }
              table.dataTable.row-border,
              table.dataTable.display {
                tbody {
                  th,
                  td {
                    border-top: 1px solid $dark-border-color;
                  }
                }
              }
              table.dataTable.display,
              table.dataTable.order-column.hover {
                tbody {
                  tr.even {
                    &:hover {
                      > .sorting_1 {
                        background-color: $dark-even-hover-sorting;
                      }
                    }
                  }
                }
              }
              table.dataTable.cell-border {
                th,
                td {
                  border-top: 1px solid $dark-border-color;
                  border-right: 1px solid $dark-border-color;

                  &:first-child {
                    border-left: 1px solid $dark-border-color;
                  }
                }
              }
              table.dataTable.order-column,
              table.dataTable.display {
                tbody {
                  tr {
                    > .sorting_1,
                    > .sorting_2,
                    > .sorting_3 {
                      background-color: $dark-body-background;
                    }
                  }
                }
              }
            }
            #example-style-3_wrapper {
              #example-style-3 {
                tfoot {
                  border-top: 2px solid $dark-card-border;
                }
              }
            }
            .page-link {
              border: 1px solid $dark-card-border;
            }
            .page-item.disabled {
              .page-link {
                background-color: $dark-card-background;
                border-color: $dark-border-color;
              }
            }
            .page-link {
              color: $dark-all-font-color;
              background-color: $dark-card-background;
            }
            .page-item {
              &:hover {
                .page-link {
                  background-color: $dark-body-background;
                }
              }
            }
            .page-item.active {
              .page-link {
                background-color: $dark-body-background;
              }
            }
            .ecommerce-widget {
              .icon {
                color: $dark-body-background;
              }
              .total-num {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
            .flot-chart-container-small {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-border-color;
            }
            .product-table {
              #API-2_wrapper {
                #API-2 {
                  tbody {
                    td {
                      span,
                      p {
                        color: $dark-small-font-color;
                      }
                    }
                  }
                }
              }
              h6 {
                color: $dark-small-font-color;
              }
            }
            .border-tab.nav-tabs {
              border-bottom: 1px solid $dark-card-border;
              .nav-item {
                .nav-link {
                  color: $dark-all-font-color;
                }
              }
              .nav-link {
                &.active,
                &:focus,
                &:hover {
                  color: var(--theme-default);
                }
              }
            }
            .br-theme-bars-1to10,
            .br-theme-bars-movie,
            .br-theme-bars-pill,
            .br-theme-bars-reversed,
            .br-theme-bars-horizontal {
              .br-widget {
                a {
                  background-color: $dark-border-color;

                  &.br-active,
                  &.br-selected {
                    background-color: var(--theme-default);
                  }
                }
              }
            }
            .br-theme-bars-square {
              .br-widget {
                a {
                  border: 2px solid $dark-border-color;
                  background-color: $dark-card-background;
                  color: $dark-all-font-color;

                  &.br-active,
                  &.br-selected {
                    border: 2px solid var(--theme-default);
                    color: var(--theme-default);
                  }
                }
              }
            }
            .br-theme-fontawesome-stars,
            .br-theme-fontawesome-stars-o {
              .br-widget {
                a {
                  &.br-selected,
                  &.br-active {
                    &:after {
                      color: var(--theme-default);
                    }
                  }
                }
              }
            }
            .scroll-demo {
              border: 1px solid $dark-card-border;
            }
            .search-form {
              input {
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
                background-color: $dark-body-background;
              }
              .form-group {
                &:before {
                  background: $dark-card-background;
                }
              }
            }
            .cd-timeline-content {
              background-color: $dark-border-color;
              border-color: $dark-card-border;
              &::before {
                border-left: 7px solid $dark-border-color;
              }
            }
            .cd-timeline-block {
              &:nth-child(even) {
                .cd-timeline-content {
                  &::before {
                    border-right-color: $dark-border-color;
                    border-left: $transparent-color;
                  }
                }
              }
            }
            .breadcrumb {
              &.bg-white {
                background-color: $dark-card-background !important;
              }
            }
            .user-status {
              table {
                td,
                th {
                  border-top: none !important;
                }
              }
            }
            #donut-color-chart-morris-daily,
            #donut-color-chart-morris,
            #browser-uses-chart,
            #website-visiter-chart {
              svg {
                opacity: 0.5;
              }
            }
          }
          .wizard-4 {
            ul.anchor {
              li {
                a.disabled {
                  color: $dark-body-background;
                }
              }
            }
          }
          .gallery-with-description {
            h4 {
              color: $dark-all-font-color;
            }
            a {
              > div {
                border: 1px solid $dark-card-border;
              }
            }
            p {
              color: $dark-small-font-color;
            }
          }
          .sub-title {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }
          .navs-icon {
            li {
              a {
                color: $dark-all-font-color;
                &:hover {
                  color: var(--theme-default);
                }
              }
            }
            .separator {
              border-bottom: 1px solid $dark-card-border;
            }
          }
          .default-according {
            li {
              .text-muted {
                color: $dark-all-font-color !important;
              }
            }
          }
          .navs-icon.default-according.style-1 {
            li {
              button {
                &:hover {
                  color: var(--theme-default) !important;
                }
              }
            }
          }
          .nav-list {
            .nav-list-disc {
              li {
                a {
                  color: $dark-all-font-color;
                }
                &:hover {
                  color: var(--theme-default);
                  a {
                    color: var(--theme-default);
                  }
                }
              }
            }
          }
          .navs-dropdown {
            .onhover-show-div {
              background-color: $dark-body-background;
              box-shadow: 0 0 14px 0 $dark-body-background;
              .navs-icon {
                li {
                  p {
                    color: $white;
                  }
                  a {
                    svg {
                      path,
                      line,
                      polyline,
                      polygon,
                      rect {
                        color: $dark-all-font-color !important;
                      }
                    }
                    &:hover {
                      svg {
                        path,
                        line,
                        polyline,
                        polygon,
                        rect {
                          color: var(--theme-default) !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .onhover-dropdown {
            &:hover {
              .onhover-show-div {
                &:before {
                  border-bottom: 7px solid $dark-body-background;
                }
              }
            }
          }
          .default-according {
            .card {
              .btn-link {
                color: $dark-all-font-color;
              }
              .card-body {
                color: $dark-small-font-color;
              }
              .card-body {
                border: 1px solid $dark-card-border;
                border-top: none;
              }
            }
          }
          .border {
            border: 1px solid $dark-card-border !important;
          }
          .blog-box {
            .blog-date {
              color: $dark-all-font-color;
            }
            .blog-details,
            .blog-details-main {
              .blog-social {
                li {
                  color: $dark-small-font-color;
                  border-color: $dark-card-border;
                  &:last-child {
                    border-right: none;
                  }
                }
              }
              p {
                color: $dark-all-font-color;
              }
              .single-blog-content-top {
                border-top: 1px solid $dark-card-border;
                p {
                  color: $dark-small-font-color;
                }
              }
            }
            .blog-details-second {
              h6 {
                color: $dark-all-font-color;
              }
              .detail-footer {
                border-color: $dark-card-border;
                ul.social-list {
                  li:nth-child(n + 2) {
                    border-color: $dark-card-border;
                  }
                }
              }
            }
          }
          .comment-box {
            h4 {
              border-color: $dark-card-border;
            }
            .d-flex {
              h6 {
                span {
                  color: $dark-small-font-color;
                }
              }
              img {
                border: 1px solid $dark-border-color;
              }
            }
            p {
              color: $dark-small-font-color;
            }
            .comment-social {
              li {
                color: $dark-small-font-color;
              }
            }
            hr {
              border-top: 1px solid $dark-card-border;
            }
          }
          .add-post {
            #cke_text-box {
              border-color: $dark-card-border;
            }
          }
          .blog-list {
            .blog-details {
              h6 {
                color: $dark-all-font-color;
              }
            }
          }
          .table-hover {
            > tbody {
              > tr {
                &:hover {
                  --bs-table-accent-bg: #1d1e26;
                  td,
                  th {
                    color: $white;
                  }
                }
              }
            }
          }
          .job-search {
            .job-description {
              border-color: $dark-card-border;
              .theme-form {
                border-color: $dark-card-border;
              }
            }
          }
          ul.the-icons {
            li {
              border: 1px dotted $dark-card-border;
              color: $sidebar-submenu-font-color;
              display: inline-block;
              padding: 10px;

              &:hover {
                background: $black;
                box-shadow: 0 0 3px $dark-card-background;
              }
              em {
                display: none;
              }
            }
          }
          .button-builder-wrap {
            .box {
              background-color: $dark-card-background;
              border: 1px solid $dark-card-border;
            }
            .button-preview {
              h2 {
                color: $dark-all-font-color;
              }
            }
            pre.well {
              background-color: $dark-card-background !important;
            }
            .btn-light {
              background-color: $dark-background !important;
            }
          }
          .crm-activity {
            > li {
              + li {
                border-top: 1px solid $dark-card-border;
              }

              h6 {
                color: $dark-all-font-color;
              }
            }
            ul.dates {
              li {
                color: $dark-small-font-color;
              }
            }
          }
          #donut-color-chart-morris,
          #donut-color-chart-morris-daily {
            svg {
              text {
                fill: $dark-all-font-color;
              }
            }
          }
          .custom-card {
            .card-profile {
              img {
                background-color: $dark-card-background;
              }
            }
            .profile-details {
              h6 {
                color: $dark-small-font-color;
              }
            }
            .card-footer {
              > div {
                h6 {
                  color: $dark-small-font-color;
                }
                + div {
                  border-left: 1px solid $dark-card-inbox;
                }
              }
            }
          }
          .form-control {
            background-color: $dark-body-background;
            color: rgba(255, 255, 255, 0.5);
            border: 1px solid $dark-card-border;
          }
          .checkbox,
          .radio {
            label {
              &::before {
                background-color: $dark-body-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
            }
          }
          .dropdown-basic {
            .dropdown {
              .dropdown-content {
                background-color: $dark-body-background;
                a {
                  color: $dark-all-font-color;
                  background-color: $transparent-color;
                  border-color: $dark-card-background;
                  &:hover {
                    background-color: $dark-card-background;
                  }
                }
                .dropdown-header {
                  color: $dark-all-font-color;
                }
                .dropdown-divider {
                  border: 1px solid $dark-border-color;
                }
              }
            }
          }
          .dropup-basic {
            .dropup {
              .dropup-content {
                background-color: $dark-body-background;
                a {
                  color: $dark-all-font-color;
                  &:hover,
                  &.active {
                    background-color: $dark-card-background;
                  }
                }
              }
            }
          }
          .dropzone.dz-clickable {
            .dz-message {
              h6 {
                color: $dark-all-font-color;
              }
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .dropzone {
            .dz-preview {
              background-color: $dark-body-background;
              .dz-details {
                background-color: $dark-card-background;
              }
            }
          }
          .browser-widget {
            .flex-grow-1 {
              column-rule: 1px solid $dark-card-border;
              h4 {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .email-wrap {
            .email-app-sidebar {
              .d-flex {
                img {
                  border: 2px solid $dark-body-background;
                }
              }
              .main-menu {
                > li {
                  a {
                    color: $dark-all-font-color;
                    &:hover {
                      a {
                        color: $primary-color;
                      }
                    }
                  }
                }
              }
              ul {
                li {
                  a {
                    > .title {
                      color: $dark-all-font-color;
                    }
                    &:hover {
                      > .title {
                        color: $primary-color;
                      }
                    }
                  }
                }
              }
            }
            .email-right-aside {
              .email-body {
                .row {
                  .col-xl-4 {
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                  }
                }
                .inbox {
                  .d-flex.active {
                    background-color: $dark-body-background;
                  }
                }
              }
            }
            .flex-grow-1 {
              h6 {
                small {
                  color: $dark-small-font-color;
                  span {
                    color: $dark-small-font-color;
                  }
                }
              }
              p {
                color: $dark-small-font-color;
              }
            }
            .email-top {
              border-bottom: 1px solid $dark-card-border;
            }
            p {
              color: $dark-small-font-color;
            }
            .email-content {
              .email-top {
                .user-emailid:after {
                  border: 1px solid $dark-card-border;
                }
              }
            }
          }
          .cke_wysiwyg_frame,
          .cke_wysiwyg_div {
            background-color: $dark-body-background;
          }
          .dropdown-menu {
            background-color: $dark-body-background;
            border: 1px solid $dark-card-background;
            a {
              color: $dark-all-font-color;
              background: $transparent-color;
              border-top-color: $dark-card-background;
              &:hover {
                background-color: $dark-card-background;
              }
            }
          }
          .icon-lists {
            div {
              i {
                color: $dark-small-font-color;
              }
              &:hover {
                background-color: $dark-body-background;
                i {
                  color: $primary-color;
                }
              }
            }
          }
          .social-widget-card {
            h5,
            h4 {
              color: $dark-all-font-color;
            }
            .card-footer {
              .row {
                .col {
                  &:nth-child(n + 2) {
                    border-color: $dark-card-border;
                  }
                }
              }
            }
          }
          .b-b-light {
            border-bottom: 1px solid $dark-card-border !important;
          }
          .b-r-dark {
            border-right: 1px solid $white !important;
          }
          .testimonial {
            i {
              color: $dark-border-color;
            }
            p {
              color: $dark-all-font-color;
            }
            h5 {
              color: $dark-all-font-color;
            }
            span {
              color: $dark-small-font-color;
            }
          }
          .grid-showcase {
            span {
              border: 1px solid $dark-card-border;
            }
          }
          .grid-align {
            .row {
              background-color: $dark-border-color;
              border: 1px solid $dark-border-color;
            }
          }
          .typo-graphy {
            code {
              color: $white !important;
            }
            .txt-dark {
              color: $dark-all-font-color !important;
            }
          }
          .dropdown-page {
            code {
              color: $white !important;
            }
          }
          .card-absolute {
            .bg-primary,
            .bg-secondary {
              h5 {
                color: $white;
              }
            }
          }
          /* body end*/
        }
        .footer {
          p {
            color: $dark-all-font-color;
          }
          border-top: 1px solid $dark-body-background;
          background-color: $dark-card-background;
        }
        .custom-select {
          background: $dark-body-background;
          color: $dark-all-font-color;
        }
        .footer-fix {
          background-color: $dark-card-background;
          border-top: 1px solid $dark-border-color;
        }
      }
      .note {
        textarea {
          color: $dark-all-font-color;
        }
      }
      .dt-button-info {
        background-color: $dark-card-background;
        border: 1px solid $dark-small-font-color;

        h2 {
          background-color: $dark-card-background;
        }
      }
      .chat-box {
        .people-list {
          .search {
            .input-group {
              border-color: $dark-card-border;
            }
          }
        }
        .about {
          .name {
            color: $dark-all-font-color;
          }
        }
        .chat-menu {
          border-left: 1px solid $dark-card-border;
          .nav-tabs {
            border-bottom: 1px solid $dark-card-border;
          }
          .user-profile {
            .image {
              .icon-wrapper {
                background-color: $dark-card-background;
                box-shadow: 1px 1px 3px 1px $dark-body-background;
              }
              .avatar {
                img {
                  border: 5px solid $dark-border-color;
                }
              }
            }
            .social-media {
              a {
                color: $dark-small-font-color;
              }
            }
            .follow {
              span {
                color: $dark-small-font-color;
              }
              .follow-num {
                color: $dark-all-font-color;
              }
            }
          }
        }
        .status {
          color: $dark-small-font-color;
          p {
            color: $dark-all-font-color !important;
          }
        }
        .chat-right-aside {
          .chat {
            .chat-header {
              border-bottom: 1px solid $dark-card-border;
              .chat-menu-icons {
                li {
                  a {
                    i {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
              img {
                box-shadow: 1px 1px 4px 1px $dark-body-background;
              }
            }
            .chat-msg-box {
              .my-message {
                border: 1px solid $dark-card-border;
              }
              .message {
                color: $dark-all-font-color;
              }
              .other-message {
                background-color: $dark-body-background;
                border-color: $dark-card-border;
              }
            }
            .chat-message {
              background-color: $dark-card-background;
              border-color: $dark-card-border;
              .input-group {
                .form-control {
                  background-color: $dark-card-background;
                }
              }
            }
          }
        }
        .chat-history {
          .call-icons {
            ul {
              li {
                border: 1px solid $dark-card-border;
                a {
                  color: $dark-small-font-color;
                }
              }
            }
          }
        }
      }
      .chat-box {
        .chat-right-aside {
          .chat {
            .chat-header {
              .chat-menu-icons {
                li {
                  a {
                    i {
                      color: $dark-all-font-color;
                    }
                  }
                  svg {
                    color: $dark-all-font-color;
                  }
                }
              }
            }
          }
        }
        .chat-menu {
          background-color: $dark-card-background;
          border-color: $dark-card-border;
          .user-profile {
            .follow {
              border-color: $dark-card-border;
            }
            .digits {
              border-color: $dark-card-border;
            }
          }
        }
      }
      pre {
        background-color: $dark-border-color;
        color: $dark-all-font-color;
      }
      .scorlled {
        background-color: $dark-body-background;
      }
      .input-group-air {
        box-shadow: 0 3px 20px 0 $dark-border-color;
      }
      .input-group-text {
        background-color: $dark-card-background;
        border: 1px solid $dark-card-border;
        color: rgba(255, 255, 255, 0.6);
        i {
          color: rgba(255, 255, 255, 0.6);
        }
      }
      .input-group-solid {
        .input-group-text,
        .form-control {
          border: 1px solid $dark-border-color;
          color: $dark-all-font-color;
        }
      }
      .semilight-bg-color,
      .header-bg-color {
        background-color: $white;
      }
      .list-group-item {
        color: $dark-all-font-color;
        + .list-group-item {
          border-top-width: 0;
        }
        &.active {
          background-color: var(--theme-default) !important;
          border-color: var(--theme-default);
          color: $white;
        }
        &:hover,
        &:focus {
          z-index: 1;
          text-decoration: none;
        }
      }
      .list-group-item-action {
        &:hover:not(.active),
        &:focus {
          background-color: $dark-body-background;
        }
      }
      .list-group-flush {
        .list-group-item {
          border-left: 0;
          border-right: 0;
          border-radius: 0;
        }
      }
      .list-group-item-primary {
        color: #004085;
        background-color: #b8daff;
      }
      .list-group-item-secondary {
        color: #383d41;
        background-color: #d6d8db;
      }
      .list-group-item-success {
        color: #155724;
        background-color: #c3e6cb;
      }
      .list-group-item-danger {
        color: #721c24;
        background-color: #f5c6cb;
      }
      .list-group-item-warning {
        color: #856404;
        background-color: #ffeeba;
      }
      .list-group-item-info {
        color: #0c5460;
        background-color: #bee5eb;
      }
      .list-group-item-light {
        color: #818182;
        background-color: #fdfdfe;
      }
      .list-group-item-dark {
        color: #1b1e21;
        background-color: #c6c8ca;
      }
      .auth-bg {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.9);
        .authentication-box {
          .card {
            background-color: $dark-card-background;
            .theme-form {
              .form-group {
                input {
                  color: $dark-all-font-color;
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                }
                label {
                  color: $dark-all-font-color;
                }
              }
              .checkbox {
                label {
                  color: $dark-all-font-color;
                  &::before {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
          }
          h3,
          h4,
          h6 {
            color: $white;
          }
        }
      }
      .auth-bg-video {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.7);
        .authentication-box {
          h4,
          h3 {
            color: $white;
          }
          h6 {
            color: $dark-small-font-color;
          }
          .card {
            background-color: $dark-card-background;
            .theme-form {
              .form-group {
                input[type="text"],
                input[type="password"] {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
              .checkbox {
                label {
                  &::before {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
          }
        }
      }
      .error-wrapper {
        .maintenance-icons {
          li {
            i {
              color: $dark-border-color;
            }
            &:nth-child(3) {
              i {
                color: $dark-border-color;
              }
            }
          }
        }
      }
      .modal-content {
        background-color: $dark-card-background;
        .modal-header {
          .close {
            color: $dark-small-font-color;
          }
        }
        .modal-footer {
          border-top: 1px solid $dark-card-border;
        }
      }
      .comingsoon {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.9);
        .comingsoon-inner {
          h5 {
            color: $dark-all-font-color;
          }
          .countdown {
            border-top: 1px solid $dark-card-border;
            border-bottom: 1px solid $dark-card-border;
            .title {
              color: $dark-all-font-color;
            }
          }
          .coming-soon-bottom-link {
            a {
              color: $dark-all-font-color;
            }
          }
        }
      }
      .theme-form {
        .login-divider {
          border-top: 1px solid $dark-border-color;
          &:before {
            background: $dark-border-color;
            color: $dark-all-font-color;
          }
        }
      }
      .authentication-main {
        background-color: $dark-border-color;
        .auth-innerright {
          .reset-password-box {
            .card {
              background-color: $dark-card-background;
            }
            .reset-password-link {
              color: $dark-small-font-color;
            }
            .theme-form {
              .form-group {
                label {
                  color: $dark-small-font-color;
                }
                .form-control {
                  background-color: $dark-body-background;
                  color: $dark-all-font-color;
                  border-color: $dark-border-color;
                }
              }
              .opt-box {
                background-color: $dark-border-color;
              }
            }
          }
          .authentication-box {
            h4 {
              color: $dark-all-font-color;
            }
            h6 {
              color: $dark-small-font-color;
            }
            h3 {
              color: $dark-all-font-color;
            }
            .card {
              background-color: $dark-card-background;
              .theme-form {
                .form-group {
                  input[type="text"],
                  input[type="password"] {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                    color: $dark-all-font-color;
                  }
                }
                .checkbox {
                  label {
                    &::before {
                      background-color: $dark-body-background;
                      border: 1px solid $dark-border-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .vertical-menu-main {
        background-color: $dark-card-background;
      }
      .mega-menu {
        .title {
          color: $dark-all-font-color;
          border-bottom: 1px solid $dark-border-color;
        }
        .list-unstyled {
          div {
            a {
              &:hover {
                color: var(--theme-default);
              }
            }
          }
        }
      }
      .default-according {
        .card {
          background-color: $dark-card-background;
          .card-header {
            border-color: $dark-card-border;
          }
          .btn-link {
            background-color: $dark-card-background;
            border-bottom: none;
            color: $white;
          }
          .text-muted {
            color: $dark-small-font-color !important;
          }
          .card-body {
            h5 {
              color: $dark-all-font-color;
            }
          }
        }
        .bg-primary {
          .btn-link {
            background-color: var(--theme-default);
            border: 1px solid var(--theme-default);
          }
        }
        .bg-secondary {
          .btn-link {
            background-color: var(--theme-secondary);
            border: 1px solid var(--theme-secondary);
          }
        }
      }
      .collapse {
        .card-body {
          background-color: $dark-card-background;
        }
      }
      .social-list {
        .d-flex {
          .flex-grow-1 {
            span {
              color: $dark-all-font-color;
            }
          }
        }
      }
      .search-page {
        .info-block {
          + .info-block {
            border-color: $dark-card-border;
          }
        }
      }
      // box layout dark
      &.box-layout .page-wrapper .page-header .header-wrapper,
      &.box-layout.page-wrapper .page-header .header-wrapper {
        border-color: $dark-card-border;
      }
    }
    .lg-backdrop {
      background-color: $dark-body-background;
    }
    .lg-outer {
      .lg-thumb-outer {
        background-color: $dark-card-background;
      }
    }
    .drag {
      background-color: $dark-card-background;
      form {
        background: $dark-card-background;
        box-shadow: 0 0 30px $dark-border-color;
        border: 1px dotted $dark-border-color;
        input {
          background-color: $dark-body-background;
          border: 1px solid $dark-border-color;
          color: $dark-all-font-color;
        }
        .help-block {
          text-transform: capitalize;
          color: $dark-small-font-color;
        }
      }
    }
    .draggable {
      input {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
        border: 1px solid $dark-border-color;
      }
      p {
        color: $dark-small-font-color;
      }
      select {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
        border: 1px solid $dark-border-color;
      }
      .radio,
      .checkbox {
        label {
          &::before {
            background-color: $dark-body-background;
            border: 1px solid $dark-border-color;
          }
        }
      }
    }
    .select2-container--default {
      .select2-selection--multiple,
      .select2-selection--single {
        background-color: $dark-body-background;
        border: 1px solid $dark-border-color !important;
      }
      .select2-search--inline {
        .select2-search__field {
          color: $dark-all-font-color;
        }
      }
      .select2-selection--single {
        .select2-selection__rendered {
          color: $dark-all-font-color;
        }
      }
      .select2-search--dropdown {
        .select2-search__field {
          background-color: $dark-body-background;
          border: 1px solid $dark-border-color;
          color: $dark-all-font-color;
        }
      }
      .select2-results__option--highlighted[aria-selected] {
        background-color: $dark-card-background !important;
        color: $dark-all-font-color !important;
      }
    }
    .select2-dropdown {
      background-color: $dark-body-background;
      border: 1px solid $dark-border-color;
    }
    .select2-drpdwn {
      .form-control-primary {
        border-color: var(--theme-default) !important;
        color: var(--theme-default) !important;
      }
      .form-control-secondary {
        border-color: var(--theme-secondary) !important;
        color: var(--theme-secondary) !important;
      }
      .form-control-success {
        border-color: $success-color !important;
        color: $success-color !important;
      }
      .form-control-info {
        border-color: $info-color !important;
        color: $info-color !important;
      }
      .form-control-warning {
        border-color: $warning-color !important;
        color: $warning-color !important;
      }
      .form-control-danger {
        border-color: $danger-color !important;
        color: $danger-color !important;
      }
      .form-control-inverse {
        border-color: $dark-card-border !important;
        color: $white !important;
      }
      .form-control-primary-fill {
        background-color: var(--theme-default) !important;
        color: $white !important;
      }
      .form-control-secondary-fill {
        background-color: var(--theme-secondary) !important;
        color: $white !important;
      }
      .form-control-success-fill {
        background-color: $success-color !important;
        color: $white !important;
      }
      .form-control-info-fill {
        background-color: $info-color !important;
        color: $white !important;
      }
      .form-control-warning-fill {
        background-color: $warning-color !important;
        color: $white !important;
      }
      .form-control-danger-fill {
        background-color: $danger-color !important;
        color: $white !important;
      }
      .form-control-inverse-fill {
        background-color: $white !important;
        color: $black !important;
      }
    }
    .select2-container--default.select2-container--disabled {
      .select2-selection--single {
        background-color: $dark-border-color;
      }
    }
    .theme-form {
      .form-group {
        select.form-control:not([size]):not([multiple]) {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color;
        }
        input[type="text"],
        input[type="email"],
        input[type="search"],
        input[type="password"],
        input[type="number"],
        input[type="tel"],
        input[type="date"],
        input[type="datetime-local"],
        input[type="time"],
        input[type="datetime-local"],
        input[type="month"],
        input[type="week"],
        input[type="url"],
        input[type="file"],
        select {
          border-color: $dark-border-color;
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          &::-webkit-input-placeholder {
            color: $dark-small-font-color;
          }
        }
        .form-control {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
        }
        textarea {
          border-color: $dark-border-color;
        }
      }
      .form-divider {
        border-top: 1px solid $dark-border-color;
        &::before {
          background: $dark-card-background;
          color: $dark-all-font-color;
        }
      }
    }
    .CodeMirror {
      background-color: $dark-card-background;
      border: 1px solid $dark-card-border;
      color: $dark-all-font-color;
      .CodeMirror-code {
        pre {
          background: $dark-card-background;
        }
      }
    }
    .editor-statusbar {
      border-color: $dark-card-border;
    }
    .editor-toolbar {
      border-top: 1px solid $dark-card-border;
      border-left: 1px solid $dark-card-border;
      border-right: 1px solid $dark-card-border;
      a {
        color: $dark-all-font-color !important;
        &:hover,
        &.active {
          background: $dark-border-color;
        }
      }
      i.separator {
        border-left: 1px solid $dark-card-border;
        border-right: 1px solid $dark-card-border;
      }
      &.fullscreen {
        &::before {
          background: linear-gradient(
            to right,
            $dark-body-background 0,
            rgba(0, 0, 0, 0) 100%
          );
        }
        &::after {
          background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0) 0,
            $dark-body-background 100%
          );
        }
      }
    }
    .editor-toolbar.disabled-for-preview {
      a {
        &:not(.no-disable) {
          background: $dark-border-color;
        }
      }
    }
    .editor-preview {
      background-color: $dark-card-background;
    }
    .editor-toolbar.fullscreen {
      background: $dark-card-background;
      border: 1px solid $dark-small-font-color;
    }
    .u-step {
      background: $dark-border-color;
      &.active,
      &.current {
        background: var(--theme-default);
        color: $white;
      }
    }
    .u-step-title,
    .u-pearl-title {
      color: $dark-all-font-color;
    }
    .u-step-number {
      background-color: $dark-card-background;
    }
    .u-pearl {
      &:before {
        background-color: $dark-border-color;
      }
    }
    .u-pearl-number,
    .u-pearl-icon {
      background: $dark-body-background;
      border: 2px solid $dark-body-background;
    }
    .u-pearl.disabled {
      .u-pearl-icon,
      .u-pearl-number {
        background: $dark-border-color;
        border: 2px solid $dark-border-color;
      }
      &:after {
        background-color: #334053;
      }
    }
    .u-pearl.error {
      &:after {
        background-color: $dark-body-background;
      }
    }
    .note-editor.note-frame {
      border-color: $dark-card-border;
      .note-editing-area {
        .note-editable {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
        }
      }
    }
    .swal-modal {
      background-color: $dark-card-background;
      .swal-title {
        color: $dark-all-font-color;
      }
      .swal-text {
        color: $dark-small-font-color;
      }
      .swal-content__input {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
      }
    }
    .nav-tabs {
      border-bottom: 1px solid $dark-card-border;
      .nav-link {
        color: $dark-all-font-color;
        &.active {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-card-border $dark-card-border
            $dark-card-background;
        }
        &:hover,
        &:focus {
          border-color: $dark-card-border $dark-card-border $dark-card-inbox;
        }
      }
      .nav-item.show {
        .nav-link {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color $dark-border-color
            $dark-card-background;
        }
      }
    }
    .nav-primary {
      .nav-link {
        &.active {
          background-color: $primary-color;
        }
      }
    }
    .search-list {
      border: none;
      .nav-link {
        &.active {
          background-color: $primary-color;
        }
      }
    }
    .nav-tabs.nav-bottom {
      .nav-item {
        .nav-link {
          &.active,
          &.focus,
          &.hover {
            border-color: $dark-card-background $dark-border-color
              $dark-border-color;
          }
        }
      }
    }
    .border-tab.nav-left,
    .border-tab.nav-right {
      .nav-link {
        color: $dark-all-font-color;

        &.active {
          color: var(--theme-default);
        }
      }
      .show {
        > .nav-link {
          color: var(--theme-default);
        }
      }
    }
    .border-tab.nav-left.nav-secondary {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          border-left-color: var(--theme-secondary);
          color: var(--theme-secondary) !important;
        }
      }
      .show > .nav-link {
        border-left-color: var(--theme-secondary);
        color: var(--theme-secondary) !important;
      }
      .nav-item.show {
        color: var(--theme-secondary) !important;
        border-left-color: var(--theme-secondary);
      }
    }
    .border-tab.nav-left.nav-success {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $success-color !important;
        }
      }
      .show > .nav-link {
        border-left-color: $success-color;
        color: $success-color !important;
      }
      .nav-item.show {
        color: $success-color !important;
        border-left-color: $success-color;
      }
    }
    .border-tab.nav-right.nav-info {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $info-color !important;
        }
      }
      .show > .nav-link {
        border-left-color: $info-color;
        color: $info-color !important;
      }
      .nav-item.show {
        color: $info-color !important;
        border-left-color: $info-color;
      }
    }
    .border-tab.nav-secondary {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: var(--theme-secondary) !important;
        }
      }
      .nav-item {
        &.show {
          color: var(--theme-secondary) !important;
        }
      }
    }
    .border-tab.nav-success {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $success-color !important;
        }
      }
      .nav-item {
        &.show {
          color: $success-color !important;
        }
      }
    }
    .dropdown-divider {
      border-top: 1px solid $dark-border-color;
    }
    .icon-hover-bottom {
      background-color: $dark-card-background;
      box-shadow: 0px 0px 1px 1px $dark-border-color;
      .icon-title {
        color: $dark-all-font-color;
      }
      span {
        color: $dark-small-font-color;
      }
      .form-group {
        input {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          border: 1px solid $dark-border-color;
        }
      }
      .icon-first {
        i {
          color: $dark-small-font-color;
        }
      }
    }
    code {
      background-color: $dark-body-background;
      border-radius: 2px;
    }
    #cd-timeline {
      &::before {
        background-color: $dark-card-border;
      }
    }
    .timeliny {
      border-top: unset;
      border-bottom: unset;
      &::before {
        background-color: $dark-card-border;
      }
      .timeliny-dot {
        background-color: $dark-card-background;
        border: 1px solid $dark-card-border;
        &::before {
          color: $dark-all-font-color;
        }
      }
      .timeliny-timeline {
        .timeliny-timeblock {
          &.active {
            .timeliny-dot {
              &::before {
                color: $dark-all-font-color;
              }
              &::after {
                background-color: $dark-border-color;
                color: $dark-all-font-color;
                border: none;
              }
            }
          }
          &:not(.inactive):not(.active) {
            .timeliny-dot {
              &:hover {
                &::before {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .timeliny-dot {
            &:hover {
              &::after {
                background-color: $dark-card-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
    }
    .todo {
      .todo-list-wrapper {
        #todo-list {
          li {
            .task-container {
              background: $dark-card-background;
              border-bottom: 1px solid $dark-card-border;
              .task-label {
                color: $dark-all-font-color;
              }
              &:hover {
                h4 {
                  color: $white;
                }
              }
              .task-action-btn {
                .action-box {
                  &:hover:not(.active) {
                    background: $dark-card-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
            &.completed {
              .task-container {
                .task-label {
                  color: var(--theme-default);
                }
                .complete-btn {
                  &:hover {
                    border: 1px solid $success-color !important;
                  }
                }
              }
            }
          }
        }
        .todo-list-footer {
          .new-task-wrapper {
            textarea {
              background-color: $dark-card-background;
              border: 1px solid $dark-card-border;
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .general-widget {
      .cal-date-widget {
        .datepicker {
          padding: 20px;
        }
      }
      .progress-widget {
        .progress {
          background-color: unset;
        }
      }
    }
    .user-profile {
      .ttl-info {
        h6 {
          color: $dark-small-font-color;
        }
        span {
          color: $dark-all-font-color;
        }
      }
      .hovercard {
        .info {
          .title {
            a {
              color: $dark-all-font-color;
            }
          }
        }
        .user-image {
          .avatar {
            img {
              border: 10px solid $dark-card-background;
            }
          }
          .icon-wrapper {
            background-color: $dark-card-background;
          }
        }
        .tabs-scoial {
          border-bottom: none !important;
        }
      }
      .profile-post {
        .post-header {
          .d-flex {
            h5 {
              color: $dark-all-font-color;
            }
          }
        }
        .post-body {
          .post-react {
            h6 {
              color: $dark-all-font-color;
            }
          }
          .post-comment {
            li {
              label {
                a {
                  color: $dark-all-font-color;
                }
              }
            }
          }
        }
      }
    }
    .img-cropper {
      .docs-options {
        .dropdown-menu {
          > li {
            background-color: $dark-body-background;
            color: $dark-all-font-color;
            border-top: 1px solid $dark-card-border;
          }
        }
      }
    }
    .edit-profile {
      .profile-title {
        border-color: $dark-card-border;
      }
    }
    span.twitter-typeahead {
      .tt-menu {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
      }
      .tt-suggestion {
        color: $dark-all-font-color;
        background-color: $dark-body-background;
        border-top: 1px solid $dark-card-border;
        &:hover,
        &:focus {
          background-color: $dark-card-background;
        }
      }
    }
    .typography {
      small {
        color: $dark-all-font-color;
      }
    }
    .note {
      textarea {
        color: $dark-all-font-color;
        background-color: transparent !important;
        border: 1px solid transparent !important;
      }
    }
    .dt-button-info {
      background-color: $dark-card-background;
      border: 1px solid $dark-border-color;
      h2 {
        background-color: $dark-card-background;
        border-bottom: 1px solid $dark-border-color;
      }
    }
    pre {
      background-color: $dark-body-background;
    }
    .error-wrapper {
      background-color: $dark-card-background;
      .sub-content {
        color: $dark-all-font-color;
      }
    }
    .b-light {
      border: 1px solid $dark-border-color !important;
    }
    .modal-header,
    .modal-footer,
    .modal-content {
      border-color: $dark-card-border;
    }
    .modal-content {
      background-color: $dark-card-background;
      .modal-header {
        .close {
          color: $dark-all-font-color;
          font-weight: 400;
        }
      }
      .ui-front {
        .form-control {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border: 1px solid $dark-card-border;
        }
      }
    }
    .stepwizard {
      .stepwizard-row {
        &:before {
          background-color: $dark-card-border;
        }
      }
    }
    .modal {
      .theme-close {
        background-color: $dark-card-background !important;
        color: $light-all-font-color;
      }
    }
    .token.atrule,
    .token.attr-value,
    .token.keyword {
      color: $info-color;
    }
    .token {
      &.boolean,
      &.constant,
      &.deleted,
      &.number,
      &.property,
      &.symbol,
      &.tag {
        color: $danger-color;
      }
    }
    .loader-box {
      &.card-loader {
        background-color: $dark-card-background;
      }
    }
    .my-gallery {
      &.gallery-with-description {
        img {
          border: 1px solid $dark-card-border !important;
          border-bottom: none !important;
        }
      }
    }
    @each $var in $alert-name {
      $i: index($alert-name, $var);
      .alert-#{$var}.inverse {
        color: $dark-all-font-color;
      }
    }
    .alert-theme {
      span {
        + span {
          + span {
            border-left: 5px solid var(--theme-default);
            background-color: $dark-body-background;
            border-radius: 4px;
          }
        }
      }
      i {
        color: $white;
        padding: 20px;
        margin-right: 20px !important;
      }
    }
    .user-card {
      .user-deatils {
        h6 {
          color: $dark-small-font-color;
        }
      }
      .card-footer {
        .user-footer {
          h6 {
            color: $dark-small-font-color;
          }
          svg {
            path,
            rect {
              color: $dark-small-font-color;
            }
          }
        }
      }
    }
    .order-history {
      table {
        .qty-box {
          button {
            background-color: $dark-body-background !important;
            border: none !important;
          }
          .input-group {
            border-color: $dark-card-border;
          }
        }
        thead {
          tr {
            th {
              background-color: $dark-body-background;
            }
          }
        }
        tr {
          td,
          th {
            border-top: none !important;
          }
        }
      }
      .title-orders {
        background-color: $dark-body-background;
      }
    }
    .navigation-option {
      ul {
        li {
          a {
            color: $dark-all-font-color;
          }
          &:hover {
            background-color: rgba($primary-color, 0.1);
            a {
              color: $primary-color;
            }
          }
        }
      }
    }
    .product-box {
      .product-img {
        .product-hover {
          ul {
            li {
              background-color: $dark-card-background;
            }
          }
        }
      }
      .product-details {
        h6 {
          color: $dark-all-font-color;
        }
        span {
          color: $dark-small-font-color;
        }
      }
    }
    .browse {
      .browse-articles {
        ul {
          li {
            a {
              color: $dark-all-font-color;
            }
            &:hover {
              h5 {
                color: $primary-color;
              }
            }
          }
        }
      }
    }
    @each $btn-name, $btn-color in (primary, var(--theme-default)),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .radio-#{$btn-name} {
        input[type="radio"] {
          & + label {
            &::before {
              border-color: $btn-color !important;
            }
            &::after {
              background-color: $btn-color;
            }
          }
          &:checked {
            & + label {
              &::before {
                border-color: $btn-color !important;
              }
              &::after {
                background-color: $btn-color;
              }
            }
          }
        }
      }
    }
    @each $btn-name, $btn-color in (primary, var(--theme-default)),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .checkbox-#{$btn-name} {
        label {
          &::before {
            border-color: $btn-color !important;
          }
        }
      }
    }
    @each $btn-name, $btn-color in (primary, var(--theme-default)),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .checkbox-solid-#{$btn-name} {
        label {
          &:before {
            background-color: $btn-color !important;
            border-color: $btn-color !important;
            color: $white !important;
          }
        }
        input[type="checkbox"] {
          &:checked {
            & + label {
              &::before {
                background-color: $btn-color !important;
                border-color: $btn-color !important;
                color: $white !important;
              }
              &::after {
                color: $white !important;
              }
            }
          }
        }
      }
    }
    .bootstrap-datetimepicker-widget {
      ul {
        li {
          color: $dark-all-font-color;
          border-bottom: 1px solid $dark-border-color;
        }
      }
      tr {
        th,
        td {
          &:hover {
            background-color: $dark-card-background;
          }
          span {
            &:hover {
              background-color: $dark-card-background;
            }
          }
          &.prev,
          &.next {
            &:hover {
              background-color: $dark-card-background;
            }
          }
        }
        &:first-child {
          th {
            &:hover {
              background-color: $dark-card-background;
            }
          }
        }
      }
    }
    .btn-transparent {
      color: $dark-all-font-color;
    }
    #cal-basic {
      .fc-toolbar {
        .fc-left,
        .fc-right {
          .fc-next-button {
            .fc-icon-right-single-arrow {
              &:after {
                color: $dark-small-font-color;
              }
            }
          }
          .fc-prev-button {
            .fc-icon-left-single-arrow {
              &:after {
                color: $dark-small-font-color;
              }
            }
          }
        }
        .fc-left {
          .fc-button-group {
            .fc-month-button {
              color: $white !important;
            }
          }
        }
      }
    }
    .fc-button-group {
      .fc-basicWeek-button,
      .fc-basicDay-button,
      .fc-agendaWeek-button,
      .fc-agendaDay-button {
        color: $black !important;
      }
      .fc-month-button {
        color: $white !important;
      }
    }
    #cal-agenda-view {
      .fc-toolbar {
        .fc-right {
          .fc-button-group {
            .fc-month-button,
            .btn-light {
              color: $black !important;
            }
            .fc-agendaWeek-button {
              color: $white !important;
              &:hover {
                color: $black !important;
              }
            }
          }
        }
      }
    }
    .basic-calendar,
    #cal-bg-events,
    #cal-event-colors {
      .fc-toolbar {
        .fc-right {
          .fc-button-group {
            .fc-basicWeek-button,
            .btn-light {
              color: $black !important;
            }
            .fc-month-button {
              color: $white !important;
              &:hover {
                color: $black !important;
              }
            }
          }
        }
      }
    }
    .faq-accordion {
      &.default-according {
        .card {
          .card-body {
            &.animate-chk {
              border: 0 !important;
            }
          }
        }
      }
    }
    .categories {
      ul {
        li.list-group-item {
          a {
            color: $dark-small-font-color;
          }
          &:hover {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .socialprofile {
      .likes-profile {
        h5 {
          span {
            color: $dark-all-font-color;
          }
        }
      }
      span {
        color: $dark-small-font-color;
      }
    }
    .social-status {
      form {
        .form-group {
          .form-control-social {
            background-color: $dark-body-background;
            border: 1px solid $dark-card-border;
          }
        }
      }
      .d-flex {
        .flex-grow-1 {
          span {
            + span {
              color: $dark-small-font-color;
            }
          }
          p,
          .light-span {
            color: $dark-small-font-color;
          }
        }
      }
    }
    .filter-cards-view,
    .timeline-content {
      p {
        color: $dark-small-font-color;
      }
      .comment-number {
        i {
          color: $dark-small-font-color;
        }
      }
    }
    .search-page {
      ul.search-info {
        .rating {
          li {
            + li {
              border-left: none;
            }
          }
        }
      }
    }
    .social-tab {
      .input-group {
        .input-group-text {
          background-color: $primary-color;
        }
      }
    }
    .social-chat {
      .flex-grow-1 {
        border: 1px solid $dark-card-border;
        &:after {
          border-right: 7px solid $dark-card-background;
        }
      }
      span {
        span {
          color: $dark-small-font-color;
        }
      }
    }
    .details-about {
      .your-details {
        p {
          color: $dark-small-font-color;
        }
      }
    }
    .activity-log {
      .my-activity {
        p {
          color: $dark-small-font-color;
        }
      }
    }
    .bookmark {
      ul {
        li {
          a {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .bookmark-tabcontent {
      .tab-content {
        .card {
          .card-header {
            border-color: $dark-card-border;
          }
        }
      }
    }
    .list-persons {
      .profile-mail {
        .email-general {
          border-color: $dark-card-border;
        }
      }
    }
    .form-bookmark {
      .form-group {
        .select2-container {
          .select2-selection--single {
            border-color: $dark-card-border !important;
          }
        }
      }
    }
    .calendar-basic {
      #menu-navi {
        border-color: $dark-card-border;
      }
    }
    .wizard-4 {
      ul.anchor {
        background-color: $dark-card-background;
        li {
          h5 {
            color: $dark-all-font-color;
          }
          small {
            color: $dark-small-font-color;
          }
          .selected {
            h5 {
              color: $primary-color;
            }
            small {
              color: $primary-color;
            }
          }
        }
      }
      .step-container {
        background-color: $dark-body-background;
      }
    }
    .apexcharts-tooltip.light {
      border-color: $dark-card-border;
      background-color: $dark-body-background;
      .apexcharts-tooltip-title {
        border-color: $dark-card-border;
      }
      .apexcharts-tooltip-text-label,
      .apexcharts-tooltip-text-value {
        color: $white;
      }
    }
    @media screen and (max-width: 1660px) {
      .chat-box {
        .chat-history {
          .total-time {
            h2 {
              color: $dark-all-font-color;
            }
          }
        }
      }
      .caller-img {
        img {
          opacity: 0.7;
        }
      }
      .chat-box {
        .chat-history {
          .call-content {
            > div {
              background-color: $dark-card-background;
              background-blend-mode: overlay;
            }
          }
        }
      }
    }
    @media only screen and (max-width: 1199.98px) {
      .left-header {
        .mega-menu-container {
          border-left: 1px solid $dark-card-border;
          .mega-box {
            + .mega-box {
              border-left: none;
            }
          }
        }
      }
    }
    @media only screen and (max-width: 1199px) {
      .mobile-title {
        &.d-none {
          border-bottom: 1px solid $dark-card-border;
        }
      }
      .chat-menu {
        border-top: 1px solid $dark-border-color;
        background-color: $dark-card-background;
      }
      .error-wrapper {
        .maintenance-icons {
          li {
            i {
              color: $dark-color;
            }
          }
        }
      }
      .md-sidebar {
        .md-sidebar-aside {
          background-color: $dark-card-background;
          border-color: $dark-card-border;
        }
      }
    }
    @media only screen and (max-width: 575.98px) {
      .user-profile {
        .hovercard {
          .info {
            .user-designation {
              border-top: 1px solid $dark-border-color;
              border-bottom: 1px solid $dark-border-color;
            }
          }
        }
      }
      .page-header {
        .header-wrapper {
          .left-header {
            ul {
              li {
                .search-form {
                  .form-control-plaintext {
                    background-color: $dark-body-background;
                  }
                }
              }
            }
          }
        }
      }
      .page-wrapper {
        &.compact-wrapper {
          .page-header {
            .header-wrapper {
              .toggle-sidebar {
                border-right: 1px solid $dark-card-border;
              }
            }
          }
        }
      }
    }
    @media only screen and (max-width: 991.98px) {
      .page-wrapper {
        &.compact-wrapper {
          .page-header {
            .header-wrapper {
              .toggle-sidebar {
                svg {
                  stroke: $white;
                }
                &:hover {
                  background-color: $primary-color;
                }
              }
            }
          }
        }
      }
      .page-wrapper {
        &.compact-wrapper {
          .left-header {
            .mega-menu {
              svg {
                stroke: $dark-all-font-color;
              }
            }
          }
        }
      }
    }
    @media only screen and (max-width: 767.98px) {
      .contacts-tabs {
        .nav-pills {
          border-right: none;
          border-bottom: 1px solid $dark-card-border;
        }
      }
      .page-wrapper {
        &.compact-wrapper {
          .left-header {
            .mega-menu {
              svg {
                stroke: $white;
              }
              .nav-link {
                border-left: 1px solid $dark-card-border;
              }
            }
          }
        }
        &.material-icon {
          .page-header {
            background-color: $dark-card-background;
          }
        }
      }
      .left-header {
        .input-group {
          .form-control {
            background-color: $dark-card-background;
          }
        }
      }
    }
  }
  .for-dark {
    display: none;
  }
  &[class*="dark-"] {
    .horizontal-wrapper {
      .for-dark {
        display: block;
      }
      .for-light {
        display: none;
      }
    }
  }
}
/**=====================
  68. theme layout CSS ends
==========================**/