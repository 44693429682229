.quiz-card-header {
  padding: 12px !important;
}

.quiz-option {
  padding: 12px;
  border: 1px solid #eee;

  button {
    padding: 3px 10px;
    width: 75px;
  }

  .progress {
    width: 75px;
  }

  .show-color {
    color: $light-text;
  }
}

.tooltip-inner {
  max-width: 250px !important;
}

.quiz-border {
  border: 1px solid #eee;
}

.password-modal {
  width: 330px;
  margin: auto;
}

.quiz-title {
  margin-bottom: 8px !important;
  padding: 0 0 0 10px;
  background-color: $body-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  span {
    display: inline-block;
    width: 140px;
    color: $theme-font-color;
  }

  .quiz-value {
    display: inline;
    width: max-content;
    background-color: $primary-color;
    color: white;
    padding: 6px;
    border-radius: 4px;
  }
}