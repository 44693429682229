.main-card {
  overflow-x: scroll;
}

.student-card {
  width: 600px;
  margin: auto;
  position: relative;
  display: flex;

  >img {
    width: 118%;
  }
}

.student-card-bg {
  // margin-left: -56px;
}

.img-full {
  height: 100%;
  width: 100%;
}

.id-card-front {
  position: absolute;
  top: 265px;
  left: 38px;
  width: 232px;
  height: 259px;
}

.id-card-back {
  position: absolute;
  top: 266px;
  width: 232px;
  right: 30px;
  height: 382px;
}

.id-card-name {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 67px;
  font-size: 11px;
}

.card-back {
  width: 300px;
  position: relative;
}

.card-front {
  height: 696px;
  width: 300px;
  position: relative;
}

.id-card-id {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 244px;
  font-size: 11px;
}

.id-card-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  bottom: 32px;
  font-size: 11px;
  position: absolute;
}

.card-detail-container {
  height: 380px;
  position: relative;
}

.id-card-image {
  position: absolute;
  z-index: 1;
  border-radius: 100%;
  object-fit: cover;
  height: 98px;
  width: 98px;
  left: 67px;
  bottom: 161px;
  border: 3px solid #36A9E1;
}

.qr-code-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  bottom: 110px;
  // .qr-code-main {
  //   position: relative;
  //   display: inline-block;
  //   border: 5px solid black;
  //   width: 150px;
  //   height: 150px;
  // }
  // .qr-code-main::before {
  //   content: "";
  //   position: absolute;
  //   height: calc(100% + 10px);
  //   width: 50%;
  //   background-color: white;
  //   top: -5px;
  //   left: 25%;
  // }
  // .qr-code-main::after {
  //   content: "";
  //   position: absolute;
  //   height: 50%;
  //   width: calc(100% + 10px);
  //   background-color: white;
  //   top: 25%;
  //   left: -5px;
  // }
}