.result-image-wrapper {
  overflow: hidden;
  margin-bottom: 10px;
  img {
    height: 100%;
    width: 100%;
  }
}
.result-text {
  text-align: center;
}
.danger-color {
  color: $danger-color;
}
.success-color {
  color: $success-color;
}
