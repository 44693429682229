/**=====================
    18. Print CSS Start
==========================**/
@media print {
  .page-wrapper {
    .page-title {
      visibility: hidden;
    }

    .page-body-wrapper {
      .page-title {
        display: none;
      }
    }
  }

  table.fixedHeader-floating {
    display: none;
  }

  .page-header {
    display: none;
  }

  .sidebar-wrapper {
    display: none;
  }

  .customizer-links {
    display: none;
  }

  .student-card-btn {
    display: none;
  }

  .main-card {
    overflow-x: auto;
  }

  .card-front {
    >img {
      opacity: 0;
    }
  }

  .card-back {
    >img {
      opacity: 0;
    }
  }

  .footer {
    display: none;
  }

  .customizer-contain {
    display: none;
  }

  .page-body {
    margin-left: 0 !important;
  }

  .student-card>img {
    display: none;
  }

  .card-detail-container {
    border: 1px solid #eee;
  }
}

.invoice-table {
  margin-top: 16px;
  margin-bottom: 16px;
}

.invoice {
  .text-end {
    input {
      margin-top: 6px;
      width: inherit;
    }
  }

  .d-flex {
    align-items: center;
  }
}

/**=====================
    18. Print CSS Ends
==========================**/