.question-progress {
  position: fixed;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  padding: 50px 30px 30px 30px;
  left: 0;
  background-color: white;
  right: 0;
  top: 0;
  width: 100%;

  .question-count {
    text-align: center;
  }
}
.small-progress {
  height: 10px;
}

.quiz-progress-bar {
  position: fixed;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;

  .progress {
    border-radius: 0;
  }
}

.check-box {
  position: absolute;
  opacity: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer !important;
}

.option-label {
  pointer-events: all;
  display: flex;
  align-items: center;
  line-height: 1.5;
  color: $theme-body-sub-title-color;
  padding: 10px 35px 10px 10px;
  letter-spacing: 0px;
  border-radius: 0.35rem;
  white-space: normal;
  font-family: $font-Montserrat, $font-serif;
  background: $body-color;
  font-weight: 500;
  cursor: pointer !important;
  margin-bottom: 0px !important;
  transition: 0.1s ease-in-out;
}

.checkedColor:hover+label {
  background-color: rgba(246, 248, 252, 0.5);
}

.checkedColor:checked+label {
  background-color: $primary-color;
  color: white;
}

.question-image-wrapper {
  width: 100%;
  height: 250px;
  margin-bottom: 10px;
  overflow-y: auto;
  overflow-x: hidden;

  .question-image {
    width: 100%;
  }
}

.countdown-timer {
  background: $primary-color;
  width: 300px;
  margin-left: auto;
  margin-top: 10px;
  border-bottom-left-radius: 50px;
  color: $white;

  p {
    margin-left: 20px;
    margin-bottom: 0;
  }

  .timer {
    margin-left: 30px;
  }
}


.timer {
  margin-left: 37px;
}

.quiz-count-down {
  background: white;
  width: fit-content;
  min-width: 170px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 7px;
  position: fixed;
  top: 100px;
  right: 0;
  span {
    color: $theme-font-color;
  }
}

.quiz-btn {
  min-width: 120px;
}

.checkedColor:checked+.inncorrect-answer {
  background-color: #E1774F !important;
  color: $white;
  border: 0;
}

.correct-answer {
  color: $white;
  border: 0;
  background: rgb(86, 171, 166) !important;
}

.checkedColor:checked+.inncorrect-answer::before {
  height: 20px;
  width: 28px;
  mask: url('../../images/x-mark-16.png') 0px 0px no-repeat;
  right: 5px;
  line-height: 100%;
  background-position: 50% center;
  background-repeat: no-repeat;
  background-color: rgb(255, 255, 255);
  pointer-events: none;
  cursor: pointer !important;
  position: absolute;
  display: block;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "";
}

.correct-answer::before {
  height: 20px;
  width: 28px;
  mask: url('../../images/checkmark-16.png') 0px 0px no-repeat;
  line-height: 100%;
  background-position: 50% center;
  background-repeat: no-repeat;
  background-color: rgb(255, 255, 255);
  pointer-events: none;
  cursor: pointer !important;
  position: absolute;
  display: block;
  top: 50%;
  right: 5px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "";
}