.progress-timeline {
    margin-bottom: 20px;
  .timeline {
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    border-radius: 100%;
    transition: all 0.3s ease-in;
    color: $theme-body-font-color;
    min-height: 25px;
    min-width: 25px;
  }
  .timeline-active {
    background: $primary-color;
    color: $white;
  }
  .line {
    height: 5px;
    background: $white;
    transition: all 0.3s ease-in;
    width: 100%;
  }
  .line-active {
    background: $primary-color;
  }
}
