/**=====================
    57. Learning CSS start
==========================**/
.learning-box {
  .details-main {
    margin: 30px;

    h6 {
      font-size: 20px;
    }

    p {
      color: $semi-dark;
      margin-bottom: 0;
    }
  }
}
.course-detail-icon {
  margin: 20px 0 0;
  padding: 0 10px;
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 0 0;
  gap: 10px;
  grid-template-areas:  ". . ." ". .";
      i {
        margin-right: 10px !important;
      }
}
.course-name-outline {
  > div {
    gap: 10px;
  }
}

.mobile-menu-btn {
  display: none;
}

.screenshot-view {
  cursor: pointer;
}

.fc-event-title {
  text-transform: capitalize;
}

.md-sidebar-aside {
  &.custom-scrollbar {
    &::-webkit-scrollbar {
      width: 4px;
    }
  }
}

.learning-header {
  margin-bottom: 5px;
}

.categories {
  padding: 20px 0;

  .learning-header {
    padding-left: 30px;
    font-size: 16px;
  }

  ul.list-group {
    li.list-group-item {
      padding: 5px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        color: $light-text;
        font-weight: 500;
      }

      &:hover {
        background-color: $light-gray;

        a {
          color: $primary-color;
        }
      }
    }
  }
}

.upcoming-course {
  .d-flex {
    .flex-grow-1 {
      i {
        +i {
          margin-left: 3px;
        }
      }

      h6 {
        margin-top: 5px;
      }
    }

    +.d-flex {
      margin-top: 30px;
    }
  }
}

.receipt-text {
  cursor: pointer;
}

.job-accordion {
  margin-bottom: 0;
}

.course-main-image {
  height: 300px !important;
  object-fit: cover;
}

@media screen and (min-width: 1200px) and (max-width: 1366px) {
  .learning-comment {
    float: left !important;
  }

  .blog-box {
    .blog-details {
      .blog-social {
        li {
          &:nth-child(n + 2) {
            padding-left: 15px;
            margin-left: 15px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .categories {
    padding: 20px 0;

    .learning-header {
      padding-left: 20px;
    }

    ul {
      li {
        padding: 5px 20px;
      }
    }
  }

  .upcoming-course {
    .d-flex {
      +.d-flex {
        margin-top: 20px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .categories {
    ul {
      li {
        a {
          font-size: 13px;
        }
      }
    }
  }

  .comment-box {
    h4 {
      margin-bottom: 20px;
      padding-bottom: 15px;
    }

    li {
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 575px) {
  .categories {
    padding: 15px 0;

    .learning-header {
      padding-left: 15px;
    }
  }

  .upcoming-course {
    .d-flex {
      +.d-flex {
        margin-top: 10px;
      }
    }
  }
}

/**=====================
    57. Learning CSS end
==========================**/