.course-radio {
    label {
        width: 100%;

        &::before {
            display: none !important;
        }

        &::after {
            display: none !important;
        }
    }

    input {
        display: none;
    }

    .course-img {
        // height: 100px;
        width: 80px;
        border-radius: 10px;
        object-fit: cover;
    }

    .course-radio-card {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 5px;
        border-radius: 8px;

        &:hover {
            background: rgba(0, 0, 0, 0.06);
        }
    }

    .selected-course {
        background: rgba(0, 0, 0, 0.06);

    }
}